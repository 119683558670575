import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BlockTenantLeadsNotePopup({
    note,
    setNoteValue
}: {
    note: string;
    setNoteValue: any;
}) {
    return (
        <div className="tenant-leads__note-popup">
            <div className="overlay" onClick={() => setNoteValue("")}></div>
            <div className="tenant-leads__note-text">
                <FontAwesomeIcon
                    icon={faClose}
                    onClick={() => setNoteValue("")}
                />
                <p>{note}</p>
            </div>
        </div>
    );
}
