import { LEASING_API } from "../../apiList";
import Collection from "../valueObject/Collection";

const fakeTenantLeads = [
    {
        auditInfo: {
            createdOn: "2024-01-09T17:09:34.988+00:00",
            createdById: "c0141b0c-7b92-4b0c-8e5e-29188f03ab2a",
            modifiedById: "c0141b0c-7b92-4b0c-8e5e-29188f03ab2a",
            lastModified: "2024-01-09T17:09:34.988+00:00"
        },
        tenantLeadId:
            "c0141b0c-7b92-4b0c-8e5e-29188f03ab2a~2024-0109-170934-988",
        email: "aqureshi@gparency.com",
        firstName: "Abrar",
        lastName: "Qureshi",
        phoneNumber: "+12404231847",
        propertyType: "OFFICE",
        city: "Oaklyn",
        state: "NJ",
        squareFootage: 3000,
        monthlyBudget: 1500,
        renterType: "FIRST_TIME_RENTER",
        leaseTerm: "TWO_YEARS",
        notes: "Test Notes",
        moveInTimeframe: "ONE_TO_THREE_MONTHS",
        // status: "UNCONFIRMED",
        propertyTypeId: 2
    },
    {
        auditInfo: {
            createdOn: "2024-01-09T17:10:13.288+00:00",
            createdById: "default",
            modifiedById: "3a20c409-a886-4779-8a73-204d1597566e",
            lastModified: "2024-01-09T17:10:15.798+00:00"
        },
        tenantLeadId:
            "3a20c409-a886-4779-8a73-204d1597566e~2024-0109-171013-288",
        email: "random@gparency.com",
        firstName: "Abrar",
        lastName: "Qureshi",
        phoneNumber: "2404231848",
        propertyType: "OFFICE",
        city: "Oaklyn",
        state: "NJ",
        squareFootage: 3000,
        monthlyBudget: 1500,
        renterType: "FIRST_TIME_RENTER",
        leaseTerm: "TWO_YEARS",
        notes: "Test Notes",
        moveInTimeframe: "ONE_TO_THREE_MONTHS",
        status: "UNCONFIRMED",
        propertyTypeId: 2
    },
    {
        auditInfo: {
            createdOn: "2024-01-22T14:22:13.640+00:00",
            createdById: "adafd7a5-6799-4903-8c34-500b4bf87f7b",
            modifiedById: "adafd7a5-6799-4903-8c34-500b4bf87f7b",
            lastModified: "2024-01-22T14:22:13.999+00:00"
        },
        tenantLeadId:
            "adafd7a5-6799-4903-8c34-500b4bf87f7b~2024-0122-142213-640",
        email: "milan.masnikosa@forwardslashny.com",
        firstName: "Milan",
        lastName: "Masnikosa",
        phoneNumber: "(443) 856-7419",
        propertyType: "OFFICE",
        city: "Oaklyn",
        state: "NJ",
        squareFootage: 3000,
        monthlyBudget: 1500,
        renterType: "FIRST_TIME_RENTER",
        leaseTerm: "TWO_YEARS",
        moveInTimeframe: "ONE_TO_THREE_MONTHS",
        propertyTypeId: 2
    },
    {
        auditInfo: {
            createdOn: "2024-01-22T14:27:16.038+00:00",
            createdById: "adafd7a5-6799-4903-8c34-500b4bf87f7b",
            modifiedById: "adafd7a5-6799-4903-8c34-500b4bf87f7b",
            lastModified: "2024-01-22T14:27:16.107+00:00"
        },
        tenantLeadId:
            "adafd7a5-6799-4903-8c34-500b4bf87f7b~2024-0122-142716-038",
        email: "milan.masnikosa@forwardslashny.com",
        firstName: "Milan",
        lastName: "Masnikosa",
        phoneNumber: "(443) 856-7419",
        propertyType: "OFFICE_BUILDING",
        city: "Oaklyn",
        state: "NJ",
        squareFootage: 3000,
        monthlyBudget: 1500,
        renterType: "FIRST_TIME_RENTER",
        leaseTerm: "TWO_YEARS",
        moveInTimeframe: "ONE_TO_THREE_MONTHS",
        propertyTypeId: 2
    },

    {
        auditInfo: {
            createdOn: "2024-01-09T17:09:34.988+00:00",
            createdById: "c0141b0c-7b92-4b0c-8e5e-29188f03ab2a",
            modifiedById: "c0141b0c-7b92-4b0c-8e5e-29188f03ab2a",
            lastModified: "2024-01-09T17:09:34.988+00:00"
        },
        tenantLeadId:
            "c0141b0c-7b92-4b0c-8e5e-29188f03ab2a~2024-0109-879879-988",
        email: "aqureshi@gparency.com",
        firstName: "Abrar",
        lastName: "Qureshi",
        phoneNumber: "+12404231847",
        propertyType: "OFFICE",
        city: "Oaklyn",
        state: "NJ",
        squareFootage: 3000,
        monthlyBudget: 1500,
        renterType: "FIRST_TIME_RENTER",
        leaseTerm: "TWO_YEARS",
        notes: "Test Notes",
        moveInTimeframe: "ONE_TO_THREE_MONTHS",
        // status: "UNCONFIRMED",
        propertyTypeId: 2
    },
    {
        auditInfo: {
            createdOn: "2024-01-09T17:10:13.288+00:00",
            createdById: "default",
            modifiedById: "3a20c409-a886-4779-8a73-204d1597566e",
            lastModified: "2024-01-09T17:10:15.798+00:00"
        },
        tenantLeadId:
            "3a20c409-a886-4779-8a73-204d1597566e~2024-787987-171013-288",
        email: "random@gparency.com",
        firstName: "Abrar",
        lastName: "Qureshi",
        phoneNumber: "2404231848",
        propertyType: "OFFICE",
        city: "Oaklyn",
        state: "NJ",
        squareFootage: 3000,
        monthlyBudget: 1500,
        renterType: "FIRST_TIME_RENTER",
        leaseTerm: "TWO_YEARS",
        notes: "Test Notes",
        moveInTimeframe: "ONE_TO_THREE_MONTHS",
        status: "UNCONFIRMED",
        propertyTypeId: 2
    },
    {
        auditInfo: {
            createdOn: "2024-01-22T14:22:13.640+00:00",
            createdById: "adafd7a5-6799-4903-8c34-500b4bf87f7b",
            modifiedById: "adafd7a5-6799-4903-8c34-500b4bf87f7b",
            lastModified: "2024-01-22T14:22:13.999+00:00"
        },
        tenantLeadId:
            "adafd7a5-6799-4903-8c34-500b4bf87f7b~2024-0122-141213-640",
        email: "milan.masnikosa@forwardslashny.com",
        firstName: "Milan",
        lastName: "Masnikosa",
        phoneNumber: "(443) 856-7419",
        propertyType: "OFFICE",
        city: "Oaklyn",
        state: "NJ",
        squareFootage: 3000,
        monthlyBudget: 1500,
        renterType: "FIRST_TIME_RENTER",
        leaseTerm: "TWO_YEARS",
        moveInTimeframe: "ONE_TO_THREE_MONTHS",
        propertyTypeId: 2
    },
    {
        auditInfo: {
            createdOn: "2024-01-22T14:27:16.038+00:00",
            createdById: "adafd7a5-6799-4903-8c34-500b4bf87f7b",
            modifiedById: "adafd7a5-6799-4903-8c34-500b4bf87f7b",
            lastModified: "2024-01-22T14:27:16.107+00:00"
        },
        tenantLeadId:
            "adafd7a5-6799-4903-8c34-500b4bf87f7b~78787-0122-142716-038",
        email: "milan.masnikosa@forwardslashny.com",
        firstName: "Milan",
        lastName: "Masnikosa",
        phoneNumber: "(443) 856-7419",
        propertyType: "OFFICE_BUILDING",
        city: "Oaklyn",
        state: "NJ",
        squareFootage: 3000,
        monthlyBudget: 1500,
        renterType: "FIRST_TIME_RENTER",
        leaseTerm: "TWO_YEARS",
        moveInTimeframe: "ONE_TO_THREE_MONTHS",
        propertyTypeId: 2
    }
];

export default class TenantLeadGateway {
    getTenantLeads(params: any): Promise<any> {
        // return Promise.resolve(fakeTenantLeads);
        return LEASING_API.get("/tenant-leads", this._mapQueryParams(params));
    }

    // TODO finish query params mapping
    private _mapQueryParams(params: any) {
        return new Collection({
            matchWithREO: true
            // state: 30,
            // propertyType: 2,
            // city: "Oaklyn"
        });
    }
}
