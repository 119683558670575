import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import responsiveSliceState, {
    setCurrentView,
    setOverlays
} from "../../../store/responsive";
import {
    faMinus,
    faPlus,
    faCheck,
    faClose,
    faArrowLeft,
    faBuildingColumns
} from "@fortawesome/pro-regular-svg-icons";
import { Collapse } from "antd";
import { useState } from "react";
import { hotjar } from "react-hotjar";
import FreddieMac from "../../../assets/images/lenders/freddie_mac.png";
import FreddieMay from "../../../assets/images/lenders/fannie_mae.png";

const { Panel } = Collapse;

import fannieFreddieLendersList, {
    IFannieFreddyLender
} from "../../../assets/static_data/freddie_fannie_mf_lenders";
import PartButton from "../../parts/PartButton";

interface FannieFreddieLenderPanelProps {
    lender: IFannieFreddyLender;
}

const checkOrCloseIcon = (isCheck: boolean) => (
    <FontAwesomeIcon icon={isCheck ? faCheck : faClose} />
);

const FannieFreddieLenderPanel = ({
    lender
}: FannieFreddieLenderPanelProps) => {
    return (
        <div className="fannie-freddie-lenders__lender">
            <div className="fannie-freddie-lenders__lender__table">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <img src={FreddieMac} alt="Freddie Mac" />
                            </th>
                            <th>
                                <img src={FreddieMay} alt="Freddie May" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Conventional / DUS</th>
                            <td>
                                {checkOrCloseIcon(
                                    lender.freddie_conventional_dus
                                )}
                            </td>
                            <td>
                                {checkOrCloseIcon(
                                    lender.fannie_conventional_dus
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>SBL / Small Loan</th>
                            <td>{checkOrCloseIcon(lender.freddie_sbl)}</td>
                            <td>{checkOrCloseIcon(lender.fannie_sbl)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="fannie-freddie-lenders__lender__contact-info">
                {lender.contact_info.map((info) => (
                    <div key={info.phone}>
                        <div>{info.officer}</div>
                        <div>{info.title}</div>
                        <div>{info.street_address}</div>
                        <div>
                            {info.city ? `${info.city}, ` : ""}
                            {info.state} {info.zip}
                        </div>
                        <div>
                            <a
                                href={`tel:+1${info.phone}`}
                                onClick={() =>
                                    hotjar.event(
                                        `Agency List - Phone Click - ${lender?.name}`
                                    )
                                }
                            >
                                {info.phone}
                            </a>
                        </div>
                        <div>
                            <a
                                href={`mailto:${info.email}`}
                                onClick={() =>
                                    hotjar.event(
                                        `Agency List - Email Click - ${lender?.name}`
                                    )
                                }
                            >
                                {info.email}
                            </a>
                        </div>
                        <div>{info.lending_area}</div>
                        <a
                            href={lender.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() =>
                                hotjar.event(
                                    `Agency List - Website Click - ${lender?.name}`
                                )
                            }
                        >
                            {lender.name}
                        </a>
                    </div>
                ))}
            </div>
            {lender.primary_contact_info && (
                <div className="fannie-freddie-lenders__lender__primary-contact-info">
                    <h3>GPARENCY Primary Contact</h3>
                    {lender.primary_contact_info.map((info) => (
                        <div key={info.officer}>
                            <div>{info.officer}</div>
                            <div>{info.title}</div>
                            <div>{info.street_address}</div>
                            <div>
                                {info.city ? `${info.city}, ` : ""}
                                {info.state} {info.zip}
                            </div>
                            <div>
                                <a
                                    href={`tel:+1${info.phone}`}
                                    onClick={() =>
                                        hotjar.event(
                                            `Agency List - Phone Click - ${lender?.name}`
                                        )
                                    }
                                >
                                    {info.phone}
                                </a>
                            </div>
                            <div>
                                <a
                                    href={`mailto:${info.email}`}
                                    onClick={() =>
                                        hotjar.event(
                                            `Agency List - Email Click - ${lender?.name}`
                                        )
                                    }
                                >
                                    {info.email}
                                </a>
                            </div>
                            <div>{info.lending_area}</div>
                            <a
                                href={lender.url}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() =>
                                    hotjar.event(
                                        `Agency List - Website Click - ${lender?.name}`
                                    )
                                }
                            >
                                {lender.name}
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const BlockFannieFreddieLenders = () => {
    const responsiveState = useSelector(responsiveSliceState);
    const dispatch = useDispatch();

    const [activeKey, setActiveKey] = useState();

    const headerHtml = (lender: IFannieFreddyLender) => {
        return (
            <div className="fannie-freddie-lenders__collapse__panel__header">
                <div>
                    <img
                        src={require(`../../../assets/images/lenders/${lender.logo}`)}
                    />
                </div>
                <div>{lender.name}</div>
                <div></div>
            </div>
        );
    };

    return (
        <div
            id="fannie-freddie-lenders"
            className={
                responsiveState.overlays.fannyFreddyLendersOverlay
                    ? "open"
                    : "closed"
            }
        >
            <div className="fannie-freddie-lenders">
                <div className="fannie-freddie-lenders__header">
                    <div className="fannie-freddie-lenders__header-wrap">
                        <PartButton
                            type="secondary"
                            onClick={() => {
                                dispatch(setCurrentView("dashboard"));
                                dispatch(
                                    setOverlays({
                                        ...responsiveState.overlays,
                                        fannyFreddyLendersOverlay: false
                                    })
                                );
                            }}
                        >
                            <span
                                onClick={() => {
                                    hotjar.event(
                                        "Fannie Freddie Lenders Collapse - mobile"
                                    );
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} /> tools
                            </span>
                        </PartButton>
                        <h3>FREDDIE MAC & FANNIE MAE MULTIFAMILY LENDERS</h3>
                    </div>
                </div>
                <div className="fannie-freddie-lenders__info">
                    The following lenders are approved by Freddie Mac and Fannie
                    Mae Multifamily (“agencies”) to offer their Conventional/DUS
                    and/or SBL/Small Loan product offerings as indicated under
                    each lender below. The listed contacts are the direct
                    executive agency contacts at these lenders, per the
                    agencies’ websites. To obtain originator-specific contacts
                    and detailed lender profiles, go to GPARENCY’s{" "}
                    <FontAwesomeIcon icon={faBuildingColumns} /> Find a Lender.
                </div>

                <div className="fannie-freddie-lenders__collapse">
                    <Collapse
                        // className="filters__dropdown-wrap"
                        accordion
                        // defaultActiveKey="1"
                        activeKey={activeKey}
                        bordered={false}
                        expandIcon={({ isActive }) => (
                            <div className="filters__dropdown-icon">
                                {isActive ? (
                                    <FontAwesomeIcon icon={faMinus} />
                                ) : (
                                    <FontAwesomeIcon icon={faPlus} />
                                )}
                            </div>
                        )}
                        expandIconPosition="end"
                        onChange={(e: any) => {
                            if (e) {
                                const lender = fannieFreddieLendersList.find(
                                    (v) => v.id == e
                                );
                                hotjar.event(
                                    `Agency List - Expand - ${lender?.name}`
                                );
                            } else {
                                const lender = fannieFreddieLendersList.find(
                                    (v) => v.id == activeKey
                                );
                                hotjar.event(
                                    `Agency List - Collapse - ${lender?.name}`
                                );
                            }
                            setActiveKey(e);
                        }}
                    >
                        {fannieFreddieLendersList.map((lender) => (
                            <Panel
                                header={headerHtml(lender)}
                                key={lender.id}
                                className="lender-collapse lender-collapse--video"
                                forceRender
                            >
                                <FannieFreddieLenderPanel
                                    key={lender.id}
                                    lender={lender}
                                ></FannieFreddieLenderPanel>
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </div>
        </div>
    );
};

export default BlockFannieFreddieLenders;
