import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setCalculatorIframe,
    setLeasingAdminSlideout,
    setShareCalculatorPopup
} from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkLarge } from "@fortawesome/pro-regular-svg-icons";
import IFrameMessageFactory from "../../domain/iFrameMessage/IFrameMessageFactory";
import OpenPopupMessage from "../../domain/iFrameMessage/calculator/OpenPopupMessage";
import { hotjar } from "react-hotjar";
import NewCalculatorCreated from "../../domain/iFrameMessage/calculator/NewCalculatorCreatedMessage";
import {
    setPropertyCardMarkAsFavorite,
    setPropertyFavoriteActionTriggered
} from "../../store/mapTriggers";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";
import PropertyService from "../../domain/property/PropertyService";
import SendMeATokenMessage from "../../domain/iFrameMessage/calculator/SendMeATokenMessage";
import { useAuth0 } from "@auth0/auth0-react";
import HereIsATokenMessage from "../../domain/iFrameMessage/calculator/HereIsATokenMessage";
import ShareCalculatorMessage from "../../domain/iFrameMessage/calculator/ShareCalculatorMessage";
import Collection from "../../domain/valueObject/Collection";
import ClosePopupsMessage from "../../domain/iFrameMessage/calculator/ClosePopupsMessage";
import TypeBoolean from "../../domain/valueObject/TypeBoolean";
import { Tooltip } from "antd";
import IconLeasingAdmin from "../icons/IconLeasingAdmin";

const BlockLeasingAdminPortalSlideout = () => {
    const sliceState = useSelector(indexSliceState);
    const dispatch = useDispatch();
    const [slideOutWidth, setSlideOutWidth] = React.useState(false);
    const leasingAdminIframeRef = React.useRef<any>(null);
    const { getAccessTokenSilently } = useAuth0();

    const query: PropertySearchQuery = PropertyService.getSearchQuery();

    // useEffect(() => {
    //     dispatch(setCalculatorIframe(leasingAdminIframeRef.current));
    //     window.addEventListener("message", (event) => {
    //         const message = IFrameMessageFactory.reconstitute(event.data);
    //         switch (true) {
    //             case message instanceof OpenPopupMessage:
    //                 message!.recieve(() => {
    //                     setSlideOutWidth(event.data.width);
    //                     // TODO extend sidebar div to width recieved from event.data.width
    //                 });
    //                 break;
    //             case message instanceof NewCalculatorCreated:
    //                 message!.recieve(() => {
    //                     dispatch(setPropertyCardMarkAsFavorite(true));
    //                     setTimeout(() => {
    //                         dispatch(setPropertyFavoriteActionTriggered(true));
    //                         query.apply();
    //                     }, 2000);
    //                 });
    //                 break;
    //             case message instanceof SendMeATokenMessage:
    //                 message!.recieve(async () => {
    //                     const token = await getAccessTokenSilently();
    //                     new HereIsATokenMessage().send(
    //                         sliceState.calculatorIframe,
    //                         token
    //                     );
    //                 });
    //                 break;
    //             case message instanceof ShareCalculatorMessage:
    //                 message!.recieve((shareId: string, propertyId: string) => {
    //                     // Opening the share calculator popup with the hash sent from the iframe
    //                     dispatch(
    //                         setShareCalculatorPopup({
    //                             isOpen: true,
    //                             hash: shareId,
    //                             propertyId: propertyId
    //                         })
    //                     );
    //                 }, new Collection(event.data));
    //         }
    //     });
    // }, [sliceState.calculatorIframe]);

    useEffect(() => {
        const viewportWidth = window.innerWidth;
        const hjFeedback: any = document.querySelector(
            "._hj_feedback_container"
        );
        if (hjFeedback && viewportWidth < 768)
            hjFeedback.style.display = sliceState.leasingAdminSlideout.isOpen
                ? "none"
                : "block";
    }, [sliceState.leasingAdminSlideout]);

    return (
        <div
            className={
                sliceState.leasingAdminSlideout.isOpen
                    ? "calculator-slideout calculator-slideout--open"
                    : "calculator-slideout"
            }
            style={{ maxWidth: slideOutWidth ? `${slideOutWidth}px` : "" }}
        >
            <div className="calculator-slideout__header">
                <div className="calculator-slideout__header__title">
                    <IconLeasingAdmin />
                    <span className="calculator-slideout__header__title-text">
                        Leasing Admin Portal
                    </span>
                </div>
                <div
                    onClick={() => {
                        hotjar.event(`Leasing Admin Portal - Collapse`);
                        new ClosePopupsMessage().send(
                            sliceState.calculatorIframe,
                            new TypeBoolean(true)
                        );
                        dispatch(
                            setLeasingAdminSlideout({
                                isOpen: false,
                                id: null
                            })
                        );
                    }}
                >
                    <Tooltip
                        placement="left"
                        title="Close the Leasing Admin Portal"
                    >
                        <FontAwesomeIcon
                            icon={faXmarkLarge}
                            style={{
                                height: "13px",
                                transform: "rotateY(180deg)",
                                cursor: "pointer"
                            }}
                        ></FontAwesomeIcon>
                    </Tooltip>
                </div>
            </div>
            <div className="calculator-slideout__iframe">
                <iframe
                    ref={leasingAdminIframeRef}
                    className={`calculator-slideout__iframe-element`}
                    src={process.env.REACT_APP_LEASING_ADMIN_PORTAL_IFRAME_URL}
                    width={"100%"}
                    height={"100%"}
                    allow="clipboard-write"
                />
            </div>
        </div>
    );
};

export default BlockLeasingAdminPortalSlideout;
