import {
    faChartLine,
    faLocationDot,
    faBuildingColumns,
    faPercent,
    faCircleInfo,
    faLayerGroup,
    faCalculator,
    faNewspaper,
    faTreeCity,
    faUpRightFromSquare,
    faHome,
    faBuildingUser
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import responsiveSliceState, {
    setOverlays,
    setShowMapBtn
} from "../../store/responsive";
import indexSliceState, {
    setCalculatorSlideout,
    setFindALenderAccessedFrom,
    setLeasingAdminSlideout
} from "../../store";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import IconLeasingAdmin from "../icons/IconLeasingAdmin";
import { setTriggerTenantLeadFetch } from "../../store/mapTriggers";

const BlockDashboard = () => {
    const dispatch = useDispatch();
    const sliceState = useSelector(indexSliceState);
    const responsiveSlice = useSelector(responsiveSliceState);

    return (
        <div className="dashboard">
            <div className="dashboard__property-search-wrapper">
                <h2 className="dashboard__title">PROPERTY SEARCH</h2>
                <div
                    className="dashboard__search"
                    onClick={() => {
                        hotjar.event("Dashboard Property Search Input Clicked");
                        dispatch(
                            setOverlays({
                                ...responsiveSlice.overlays,
                                propertySearch: true
                            })
                        );
                        dispatch(setShowMapBtn(false));
                    }}
                >
                    <span className="dashboard__font-icon-wrapper">
                        <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                    <input
                        type="text"
                        placeholder="Search by Address, City, State, Zip or County..."
                        className="dashboard__search-input"
                    />
                </div>
            </div>
            <div className="dashboard__tools-wrapper">
                <h2>TOOLS</h2>
                <div className="dashboard-tools">
                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            hotjar.event("My Properties Expand - mobile");
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    myPropertiesOverlay: true,
                                    indexesOverlay: false,
                                    lenderDefault: false,
                                    ratesOverlay: false,
                                    tenantLeadsOverlay: false
                                })
                            );
                            dispatch(setShowMapBtn(true));
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faTreeCity} />
                        </div>
                        <span>MY PIPELINE</span>
                    </div>

                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            dispatch(
                                setCalculatorSlideout({
                                    isOpen: true,
                                    id: null,
                                    address: ""
                                })
                            );
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faCalculator} />
                        </div>
                        <span>UNDERWRITING CALCULATOR</span>
                        {/* <div className="dashboard-tool__pill--acquisition-calc">
                            Beta
                        </div> */}
                    </div>
                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            hotjar.event("Find a Lender Expand - mobile");
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    indexesOverlay: false,
                                    lenderDefault: true,
                                    ratesOverlay: false,
                                    myPropertiesOverlay: false,
                                    tenantLeadsOverlay: false
                                })
                            );
                            dispatch(setShowMapBtn(true));
                            dispatch(setFindALenderAccessedFrom("sidebar"));
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faBuildingColumns} />
                        </div>
                        <span>FIND A LENDER</span>
                    </div>

                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            hotjar.event("Tenant Leads Expand - mobile");
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    indexesOverlay: false,
                                    lenderDefault: false,
                                    ratesOverlay: false,
                                    myPropertiesOverlay: false,
                                    tenantLeadsOverlay: true
                                })
                            );
                            dispatch(setTriggerTenantLeadFetch(true));
                            dispatch(setShowMapBtn(true));
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faBuildingUser} />
                        </div>
                        <span>TENANT LEADS</span>
                    </div>

                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            hotjar.event("Interest Rates Expand - mobile");
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    ratesOverlay: true,
                                    indexesOverlay: false,
                                    lenderDefault: false,
                                    myPropertiesOverlay: false,
                                    tenantLeadsOverlay: false
                                })
                            );
                            dispatch(setShowMapBtn(true));
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faPercent} />
                        </div>
                        <span>INTEREST RATES</span>
                        <span className="dashboard__interest-tool">
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                style={{
                                    paddingLeft: "4px",
                                    height: "16px",
                                    marginTop: "-2px"
                                }}
                            />
                            <div className="dashboard-interest-info">
                                <h5>
                                    <span className="dashboard__font-icon">
                                        <FontAwesomeIcon icon={faCircleInfo} />
                                    </span>
                                    Interest rate ranges displayed are general
                                    indicators of market conditions.
                                </h5>
                                <p>
                                    These are based on various factors,
                                    including{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                        real-time quotes from our marketplace
                                    </span>{" "}
                                    and{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                        insights from our brokers.
                                    </span>
                                </p>
                            </div>
                        </span>
                        {/* <div className="dashboard-tool__pill">Beta</div> */}
                    </div>
                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            hotjar.event("Indexes Expand - mobile");
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    indexesOverlay: true,
                                    lenderDefault: false,
                                    ratesOverlay: false,
                                    myPropertiesOverlay: false,
                                    tenantLeadsOverlay: false
                                })
                            );
                            dispatch(setShowMapBtn(true));
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faChartLine} />
                        </div>
                        <span>INDEXES</span>
                    </div>
                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            hotjar.event("Lender Overlay Expand - mobile");
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    lenderDefault: false,
                                    lenderOverlay: false,
                                    indexesOverlay: false,
                                    ratesOverlay: false,
                                    myPropertiesOverlay: false,
                                    lenderPortfoliosOverlay: true,
                                    tenantLeadsOverlay: false
                                })
                            );
                            dispatch(setShowMapBtn(true));
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faLayerGroup} />
                        </div>
                        <span>LENDER OVERLAYS</span>
                        {/* <span className="dashboard-tool__new-feature-new portfolio-new">
                                        Beta
                                    </span> */}
                    </div>
                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            hotjar.event(
                                "Fannie Freddie Lenders Expand - mobile"
                            );
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    lenderDefault: false,
                                    lenderOverlay: false,
                                    indexesOverlay: false,
                                    ratesOverlay: false,
                                    myPropertiesOverlay: false,
                                    lenderPortfoliosOverlay: false,
                                    fannyFreddyLendersOverlay: true,
                                    tenantLeadsOverlay: false
                                })
                            );
                            dispatch(setShowMapBtn(true));
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faHome} />
                        </div>
                        <span>
                            FREDDIE MAC & FANNIE MAE MULTIFAMILY LENDERS
                        </span>
                    </div>
                    <a
                        href="https://news.gparency.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="dashboard-tool"
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faNewspaper} />
                        </div>
                        <span>CRE NEWS</span>
                        <div className="dashboard-tool__pill dashboard-tool__pill--news">
                            <FontAwesomeIcon
                                icon={faUpRightFromSquare}
                                style={{
                                    color: "#22aa94",
                                    marginTop: "-5px"
                                }}
                            ></FontAwesomeIcon>
                        </div>
                    </a>
                    <div
                        className="dashboard-tool"
                        onClick={() => {
                            hotjar.event("Video Walkthrough Expand - mobile");
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    indexesOverlay: false,
                                    lenderDefault: false,
                                    ratesOverlay: false,
                                    myPropertiesOverlay: false,
                                    videoWalkthroughOverlay: true,
                                    tenantLeadsOverlay: false
                                })
                            );
                            dispatch(setShowMapBtn(true));
                        }}
                    >
                        <div className="dashboard-tool__icon">
                            <FontAwesomeIcon icon={faYoutube as IconProp} />
                        </div>
                        <span>VIDEO WALKTHROUGH</span>
                    </div>
                    {sliceState.isLeasingAdmin && (
                        <div
                            className="dashboard-tool"
                            onClick={() => {
                                hotjar.event("Leasing Admin Expand - mobile");
                                dispatch(
                                    setLeasingAdminSlideout({
                                        isOpen: true,
                                        id: null
                                    })
                                );
                                dispatch(setShowMapBtn(true));
                            }}
                        >
                            <div className="dashboard-tool__icon dashboard-tool__icon--leasing-admin">
                                <IconLeasingAdmin />
                            </div>
                            <span>LEASING ADMIN PORTAL</span>
                        </div>
                    )}
                </div>
            </div>

            {/* <div className="dashboard-tooltip">
                <p className="dashboard-tooltip__text-bold">
                    <FontAwesomeIcon icon={faCircleInfo} />
                    Interest rate ranges displayed are general indicators of
                    market conditions.{" "}
                </p>
                <p className="dashboard-tooltip__text">
                    These are based on various factors, including real-time
                    quotes from our marketplace and insights from our brokers.{" "}
                </p>
            </div> */}
        </div>
    );
};

export default BlockDashboard;
