import { useEffect, useState } from "react";
import { faHouseBuilding } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import BlockTenantLeadsNotePopup from "./BlockTenantLeadsNotePopup";
import { TenantLeadView } from "../../../domain/tenantLead/TenantLeadViewMapper";

interface BlockTenantLeadsProps {
    data: TenantLeadView[];
}

export default function BlockTenantLeadsData({ data }: BlockTenantLeadsProps) {
    const [noteValue, setNoteValue] = useState("");

    useEffect(() => {
        return () => {
            setNoteValue("");
        };
    }, []);

    return (
        <div className="tenant-leads__data">
            {noteValue.length > 0 && (
                <BlockTenantLeadsNotePopup
                    note={noteValue}
                    setNoteValue={() => setNoteValue("")}
                />
            )}
            <div className="tenant-leads__data-inner">
                {data.map((tenant: TenantLeadView) => {
                    return (
                        <div
                            className={`tenant-leads__card ${
                                tenant.status === "Unconfirmed"
                                    ? "unconfirmed"
                                    : ""
                            }`}
                            key={tenant.tenantLeadId}
                        >
                            <div className="tenant-leads__unconfirmed">
                                <FontAwesomeIcon
                                    icon={solid("triangle-exclamation")}
                                />
                                <span>UNCONFIRMED</span>
                            </div>
                            <div className="tenant-leads__card-heading">
                                <span>{`${tenant.city}, ${tenant.state}`}</span>
                                <span>
                                    <FontAwesomeIcon icon={faHouseBuilding} />
                                    <span>{tenant.propertyType}</span>
                                </span>
                            </div>
                            <div className="tenant-leads__card-data">
                                <div className="tenant-leads__card-row">
                                    <span>Tenant Name</span>
                                    <span>{`${tenant.firstName} ${tenant.lastName}`}</span>
                                </div>
                                <div className="tenant-leads__card-row">
                                    <span>Company Name</span>
                                    <span>{tenant.companyName}</span>
                                </div>
                                <div className="tenant-leads__card-row">
                                    <span>Phone Number</span>
                                    <span>
                                        <a href={`tel:${tenant.phoneNumber}`}>
                                            {tenant.phoneNumber}
                                        </a>
                                    </span>
                                </div>
                                <div className="tenant-leads__card-row">
                                    <span>SF</span>
                                    <span>{tenant.squareFootage}</span>
                                </div>
                                <div className="tenant-leads__card-row">
                                    <span>Monthly Budget</span>
                                    <span>{tenant.monthlyBudget}</span>
                                </div>
                                <div className="tenant-leads__card-row">
                                    <span>Notes</span>
                                    <span>
                                        {tenant.notes ? (
                                            <span
                                                className="tenant-leads__view-note"
                                                onClick={() =>
                                                    setNoteValue(tenant.notes)
                                                }
                                            >
                                                View Note
                                            </span>
                                        ) : (
                                            "No Note"
                                        )}
                                    </span>
                                </div>
                                <div className="tenant-leads__card-row">
                                    <span>Desired Term</span>
                                    <span>{tenant.leaseTerm}</span>
                                </div>
                                <div className="tenant-leads__card-row">
                                    <span>Move-in-Date</span>
                                    <span>{tenant.moveInTimeframe}</span>
                                </div>
                                <div className="tenant-leads__card-row">
                                    <span>Submission Date</span>
                                    <span>{tenant.submissionDate}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
