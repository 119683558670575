import TenantLeadFactory from "./TenantLeadFactory";
import TenantLeadGateway from "./TenantLeadGateway";
import TenantLeadRepository from "./TenantLeadRepository";

export default class TenantLeadService {
    static getTenantLeads(params: any): Promise<any> {
        return new TenantLeadRepository(
            new TenantLeadGateway(),
            new TenantLeadFactory()
        ).getTenantLeads(params);
    }
}
