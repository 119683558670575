import PropertyType from "../property/valueObject/PropertyType";
import Collection from "../valueObject/Collection";
import TypeFloat from "../valueObject/TypeFloat";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import TenantLeadEntity from "./TenantLeadEntity";
import LeaseTermType from "./valueObject/LeaseTermType";
import MoveInTimeframeType from "./valueObject/MoveInTimeframeType";
import RenterType from "./valueObject/RenterType";
import TenantLeadStatusType from "./valueObject/TenantLeadStatusType";

export default class TenantLeadFactory {
    reconstitute(data: Collection) {
        const lead = new TenantLeadEntity();

        if (data.has("tenantLeadId")) {
            lead.setTenandLeadId(new TypeString(data.get("tenantLeadId")));
        }
        if (data.has("email")) {
            lead.setEmail(new TypeString(data.get("email")));
        }
        if (data.has("firstName")) {
            lead.setFirstName(new TypeString(data.get("firstName")));
        }
        if (data.has("lastName")) {
            lead.setLastName(new TypeString(data.get("lastName")));
        }
        if (data.has("phoneNumber")) {
            lead.setPhoneNumber(new TypeString(data.get("phoneNumber")));
        }
        if (data.has("propertyTypeId")) {
            lead.setPropertyType(new PropertyType(data.get("propertyTypeId")));
        }
        if (data.has("city")) {
            lead.setCity(new TypeString(data.get("city")));
        }
        if (data.has("state")) {
            lead.setState(new TypeString(data.get("state")));
        }
        if (data.has("companyName")) {
            lead.setCompanyName(new TypeString(data.get("companyName")));
        }
        if (data.has("squareFootage")) {
            lead.setSquareFootage(
                new TypePositiveInteger(data.get("squareFootage"))
            );
        }
        if (data.has("monthlyBudget")) {
            lead.setMonthlyBudget(new TypeFloat(data.get("monthlyBudget")));
        }
        if (data.has("renterType")) {
            lead.setRenterType(new RenterType(data.get("renterType")));
        }
        if (data.has("leaseTerm")) {
            lead.setLeaseTerm(new LeaseTermType(data.get("leaseTerm")));
        }
        if (data.has("notes")) {
            lead.setNotes(new TypeString(data.get("notes")));
        }
        if (data.has("moveInTimeframe")) {
            lead.setMoveInTimeframe(
                new MoveInTimeframeType(data.get("moveInTimeframe"))
            );
        }
        if (data.has("status")) {
            lead.setStatus(new TenantLeadStatusType(data.get("status")));
        }
        if (data.has("auditInfo")) {
            const auditInfo = new Collection(data.get("auditInfo"));
            if (auditInfo.has("createdOn")) {
                lead.setSubmissionDate(new Date(auditInfo.get("createdOn")));
            }
        }

        return lead;
    }
}
