import Exception from "../../../utils/exceptions/Exception";
import Collection from "../../valueObject/Collection";

export default class TenantLeadStatusType {
    private static readonly UNCONFIRMED = "UNCONFIRMED";
    private static readonly UNCONFIRMED_NAME = "Unconfirmed";
    private static readonly WELCOME_EMAIL_SENT = "WELCOME_EMAIL_SENT";
    private static readonly WELCOME_EMAIL_SENT_NAME = "Welcome email sent";
    private static readonly EMAIL_WRONG = "EMAIL_WRONG";
    private static readonly EMAIL_WRONG_NAME = "Email wrong";
    private static readonly ATTEMPTING_TO_CONTACT = "ATTEMPTING_TO_CONTACT";
    private static readonly ATTEMPTING_TO_CONTACT_NAME =
        "Attempting to contact";
    private static readonly WRONG_NUMBER = "WRONG_NUMBER";
    private static readonly WRONG_NUMBER_NAME = "Wrong number";
    private static readonly CONFIRMED = "CONFIRMED";
    private static readonly CONFIRMED_NAME = "Confirmed";
    private static readonly LEASING_REP_ASSIGNED = "LEASING_REP_ASSIGNED";
    private static readonly LEASING_REP_ASSIGNED_NAME = "Leasing rep assigned";
    private static readonly INITIATING_MATCHING = "INITIATING_MATCHING";
    private static readonly INITIATING_MATCHING_NAME = "Initiating Matching";
    private static readonly MEMBER_MATCHING_COMPLETED =
        "MEMBER_MATCHING_COMPLETED";
    private static readonly MEMBER_MATCHING_COMPLETED_NAME =
        "Member Matching Completed";
    private static readonly NON_MEMBER_MATCHING_COMPLETED =
        "NON_MEMBER_MATCHING_COMPLETED";
    private static readonly NON_MEMBER_MATCHING_COMPLETED_NAME =
        "Non Member Matching Completed";
    private static readonly NON_MEMBER_MATCHING_UPLOADED =
        "NON_MEMBER_MATCHING_UPLOADED";
    private static readonly NON_MEMBER_MATCHING_UPLOADED_NAME =
        "Non Member Matching Uploaded";
    private static readonly NON_MEMBER_MATCHING_FINISHED =
        "NON_MEMBER_MATCHING_FINISHED";
    private static readonly NON_MEMBER_MATCHING_FINISHED_NAME =
        "Non Member Matching Finished";
    private static readonly TENANT_NOT_MOVING_FORWARD =
        "TENANT_NOT_MOVING_FORWARD";
    private static readonly TENANT_NOT_MOVING_FORWARD_NAME =
        "Tenant not moving forward";
    private static readonly TENANT_SIGNED_GPARENCY = "TENANT_SIGNED_GPARENCY";
    private static readonly TENANT_SIGNED_GPARENCY_NAME =
        "Tenant signed gparency";
    private static readonly TENANT_SIGNED_NON_GPARENCY =
        "TENANT_SIGNED_NON_GPARENCY";
    private static readonly TENANT_SIGNED_NON_GPARENCY_NAME =
        "Tenant signed non gparency";

    private _value: string;

    constructor(value: string) {
        this._value = value;
        this.validateValue();
    }

    get name(): string {
        return TenantLeadStatusType.getAvailableTypeNames().get(
            TenantLeadStatusType.getAvailableTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableTypes(): Collection {
        return new Collection([
            TenantLeadStatusType.UNCONFIRMED,
            TenantLeadStatusType.WELCOME_EMAIL_SENT,
            TenantLeadStatusType.EMAIL_WRONG,
            TenantLeadStatusType.ATTEMPTING_TO_CONTACT,
            TenantLeadStatusType.WRONG_NUMBER,
            TenantLeadStatusType.CONFIRMED,
            TenantLeadStatusType.LEASING_REP_ASSIGNED,
            TenantLeadStatusType.INITIATING_MATCHING,
            TenantLeadStatusType.MEMBER_MATCHING_COMPLETED,
            TenantLeadStatusType.NON_MEMBER_MATCHING_COMPLETED,
            TenantLeadStatusType.NON_MEMBER_MATCHING_UPLOADED,
            TenantLeadStatusType.NON_MEMBER_MATCHING_FINISHED,
            TenantLeadStatusType.TENANT_NOT_MOVING_FORWARD,
            TenantLeadStatusType.TENANT_SIGNED_GPARENCY,
            TenantLeadStatusType.TENANT_SIGNED_NON_GPARENCY
        ]);
    }

    public static getAvailableTypeNames(): Collection {
        return new Collection([
            TenantLeadStatusType.UNCONFIRMED_NAME,
            TenantLeadStatusType.WELCOME_EMAIL_SENT_NAME,
            TenantLeadStatusType.EMAIL_WRONG_NAME,
            TenantLeadStatusType.ATTEMPTING_TO_CONTACT_NAME,
            TenantLeadStatusType.WRONG_NUMBER_NAME,
            TenantLeadStatusType.CONFIRMED_NAME,
            TenantLeadStatusType.LEASING_REP_ASSIGNED_NAME,
            TenantLeadStatusType.INITIATING_MATCHING_NAME,
            TenantLeadStatusType.MEMBER_MATCHING_COMPLETED_NAME,
            TenantLeadStatusType.NON_MEMBER_MATCHING_COMPLETED_NAME,
            TenantLeadStatusType.NON_MEMBER_MATCHING_UPLOADED_NAME,
            TenantLeadStatusType.NON_MEMBER_MATCHING_FINISHED_NAME,
            TenantLeadStatusType.TENANT_NOT_MOVING_FORWARD_NAME,
            TenantLeadStatusType.TENANT_SIGNED_GPARENCY_NAME,
            TenantLeadStatusType.TENANT_SIGNED_NON_GPARENCY_NAME
        ]);
    }

    private validateValue() {
        if (!TenantLeadStatusType.getAvailableTypes().contains(this._value)) {
            throw new Exception("INVALID_LEASE_TERM_TYPE", 500);
        }
    }
}
