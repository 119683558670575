import TenantLeadEntity from "./TenantLeadEntity";

export type TenantLeadView = {
    tenantLeadId: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    companyName: string;
    propertyType: string;
    city: string;
    state: string;
    squareFootage: number;
    monthlyBudget: string;
    renterType: string;
    leaseTerm: string;
    notes: string;
    moveInTimeframe: string;
    status: string;
    submissionDate: string;
};

const NO_INFO = "-";

export default class TenantLeadViewMapper {
    static currencyFormatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
    });

    static map(data: TenantLeadEntity[]) {
        const leads: TenantLeadView[] = [];
        data.map((item: TenantLeadEntity) => {
            leads.push({
                tenantLeadId: item.tenandLeadId.value,
                email: item.email.value,
                firstName: item.hasFirstName() ? item.firstName.value : NO_INFO,
                lastName: item.hasLastName() ? item.lastName.value : NO_INFO,
                phoneNumber: item.hasPhoneNumber()
                    ? item.phoneNumber.value
                    : NO_INFO,
                companyName: item.hasCompanyName()
                    ? item.companyName.value
                    : NO_INFO,
                propertyType: item.hasPropertyType()
                    ? item.propertyType.name
                    : NO_INFO,
                city: item.hasCity() ? item.city.value : NO_INFO,
                state: item.hasState() ? item.state.value : NO_INFO,
                squareFootage: item.hasSquareFootage()
                    ? item.squareFootage.value.toLocaleString()
                    : NO_INFO,
                monthlyBudget: item.hasMonthlyBudget()
                    ? this.currencyFormatter.format(item.monthlyBudget.value)
                    : NO_INFO,
                renterType: item.hasRenterType()
                    ? item.renterType.name
                    : NO_INFO,
                leaseTerm: item.hasLeaseTerm() ? item.leaseTerm.name : NO_INFO,
                notes: item.hasNotes() ? item.notes.value : undefined,
                moveInTimeframe: item.hasMoveInTimeframe()
                    ? item.moveInTimeframe.name
                    : NO_INFO,
                status: item.hasStatus() ? item.status.name : NO_INFO,
                submissionDate: item.hasSubmissionDate()
                    ? this.formatDateToMonthDayYear(item.submissionDate)
                    : NO_INFO
            });
        });
        return leads;
    }

    private static formatDateToMonthDayYear(date: Date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).format(date);
    }
}
