const IconLeasingAdmin = () => {
    return (
        <svg
            width="30"
            height="24"
            viewBox="0 0 30 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Layer_1" clipPath="url(#clip0_17585_332)">
                <path
                    id="Vector"
                    d="M11.3821 7.38449C8.8374 7.38449 6.84229 9.41122 6.84229 12C6.84229 14.5888 8.83577 16.6155 11.3821 16.6155C13.9284 16.6155 15.9219 14.5458 15.9219 12C15.9219 9.4542 13.9284 7.38449 11.3821 7.38449ZM11.3821 14.0317C10.2797 14.0317 9.38374 13.1208 9.38374 12C9.38374 10.8792 10.2797 9.96832 11.3821 9.96832C12.4845 9.96832 13.3805 10.8792 13.3805 12C13.3805 13.1208 12.4845 14.0317 11.3821 14.0317Z"
                    fill="#03363D"
                />
                <path
                    id="Vector_2"
                    d="M17.1268 17.6125L16.1853 17.3299L15.6715 17.7283C15.1918 18.1019 14.6683 18.411 14.1138 18.6457L13.5203 18.8987L12.9236 21.4147H9.84063L9.24389 18.8987L8.65039 18.6457C8.09592 18.411 7.57235 18.1019 7.09267 17.7283L6.57886 17.3299L4.13171 18.0638L2.59025 15.3511L4.4374 13.564L4.3561 12.9177C4.3187 12.6118 4.29919 12.3027 4.29919 11.9985C4.29919 11.6944 4.3187 11.3852 4.3561 11.0794L4.4374 10.433L2.59025 8.64601L4.13334 5.93324L6.58048 6.66723L7.0943 6.26883C7.57397 5.89522 8.09755 5.58609 8.65202 5.35134L9.24551 5.09842L9.84226 2.58237H12.9252L13.5219 5.09842L14.1154 5.35134C14.6699 5.58609 15.1935 5.89522 15.6731 6.26883L16.187 6.66723L18.6357 5.93324L20.0471 8.41788C20.7658 7.89219 21.5739 7.57149 22.4438 7.46899L20.7658 4.51487C20.2178 3.5511 19.1024 3.09979 18.052 3.41223L16.6764 3.82386C16.3626 3.61226 16.0341 3.41885 15.6992 3.24857L15.3642 1.8335C15.1073 0.752363 14.1642 -0.00311279 13.0683 -0.00311279H9.69917C8.60324 -0.00311279 7.66015 0.752363 7.40324 1.8335L7.06828 3.24857C6.73333 3.41885 6.40487 3.61226 6.09105 3.82386L4.67642 3.41223C3.6683 3.09814 2.54472 3.56101 2.00326 4.51321L0.318713 7.48222C-0.229253 8.44598 -0.0568955 9.65442 0.738224 10.4231L1.77562 11.3885C1.76424 11.6084 1.75936 11.7985 1.75936 11.9572C1.75936 12.1804 1.76424 12.3771 1.77562 12.5705L0.736598 13.5773C-0.0552695 14.3443 -0.227627 15.5527 0.320339 16.5165L2.00489 19.4789C2.54472 20.4311 3.6683 20.8956 4.6748 20.5832L6.09268 20.1683C6.40325 20.3766 6.7317 20.57 7.06991 20.7452L7.40487 22.1619C7.66178 23.2414 8.60649 23.9969 9.7008 23.9969H13.0699C13.8601 23.9969 14.5723 23.6018 15.0032 22.9753C14.9772 22.8414 14.9577 22.7058 14.9463 22.5653C14.8731 21.6726 15.0357 20.7667 15.4325 19.874C15.8455 18.9416 16.4211 18.1812 17.13 17.6092L17.1268 17.6125Z"
                    fill="#03363D"
                />
                <path
                    id="Vector_3"
                    d="M29.5576 21.7567C29.5219 21.555 29.5284 21.3401 29.4633 21.15C29.3332 20.7698 29.2178 20.3664 29.0048 20.0358C28.1902 18.7646 27.0308 18.0521 25.53 18.0273C25.0828 18.019 24.6357 18.0124 24.1885 18.0058C23.8227 18.0967 23.4536 18.143 23.0861 18.143C22.7186 18.143 22.3381 18.0951 21.9642 18.0008C21.8438 18.0008 21.7251 18.0041 21.6048 18.0058C21.2796 18.0124 20.9544 18.0207 20.6308 18.0322C19.0178 18.0967 17.7869 18.8373 17.0439 20.2838C16.9983 20.3731 16.9544 20.464 16.9121 20.5582C16.6487 21.1517 16.5105 21.7766 16.5642 22.4312C16.613 23.0263 16.8731 23.5074 17.4292 23.7405C17.7154 23.8611 18.0406 23.9405 18.3479 23.9438C19.1056 23.9537 19.8634 23.9554 20.6211 23.9554C21.4373 23.9554 22.252 23.9521 23.0682 23.9521V23.9587C24.0178 23.9587 24.9658 23.9603 25.9154 23.9603C26.5593 23.9603 27.2048 23.9603 27.8487 23.9554C28.8763 23.9488 29.6845 23.3487 29.6487 22.1254C29.6454 22.0014 29.582 21.879 29.5593 21.7551L29.5576 21.7567Z"
                    fill="#03363D"
                />
                <path
                    id="Vector_4"
                    d="M20.2681 10.4395C19.8079 10.9784 19.5445 11.6148 19.473 12.3141C19.4177 12.858 19.4453 13.4085 19.6567 13.9342C19.951 14.6715 20.3234 15.3459 21.0079 15.7823C21.3852 16.0237 21.7705 16.2171 22.1721 16.3411C22.2795 16.3742 22.3868 16.4023 22.4973 16.4254C22.6908 16.4651 22.886 16.4866 23.0876 16.4866C23.338 16.4866 23.5949 16.4535 23.8599 16.3841C23.9022 16.3725 23.9412 16.3593 23.9835 16.3461C24.7982 16.1064 25.4811 15.6765 26.0014 14.9756C26.2177 14.683 26.4193 14.3871 26.4957 14.0201C26.5152 13.9309 26.5494 13.8449 26.5786 13.749C26.6307 13.4928 26.7103 13.2432 26.7266 12.9886C26.7412 12.7604 26.7055 12.5257 26.6583 12.2992C26.5819 11.9322 26.5006 11.5619 26.3754 11.2098C26.2876 10.9635 26.1445 10.7304 25.9868 10.5205C25.8453 10.332 25.6486 10.1865 25.4795 10.0179C24.9575 9.49553 24.3104 9.21781 23.6063 9.12358C23.4177 9.09878 23.2339 9.08556 23.0551 9.08556C22.9429 9.08556 22.8339 9.09217 22.7266 9.10043C21.7754 9.18474 20.9543 9.63605 20.2697 10.4362L20.2681 10.4395Z"
                    fill="#03363D"
                />
            </g>
            <defs>
                <clipPath id="clip0_17585_332">
                    <rect width="29.6471" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconLeasingAdmin;
