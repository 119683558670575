import { useEffect, useState } from "react";
import Switch from "antd/lib/switch";
import Table from "antd/lib/table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faChevronUp,
    faCalculator,
    faFile,
    faBell,
    faBellSlash,
    faNote
} from "@fortawesome/pro-regular-svg-icons";
import responsiveSliceState, {
    setCurrentView,
    setOverlays
} from "../../../store/responsive";
import { useDispatch, useSelector } from "react-redux";
import PartButton from "../../parts/PartButton";
import { hotjar } from "react-hotjar";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PropertyService from "../../../domain/property/PropertyService";
import FavoritePropertyViewMapper from "../../../domain/favoriteProperty/FavoritePropertyViewMapper";
import mapTriggersSliceState, {
    setPropertyCardHasAlerts,
    setPropertyFavoriteActionTriggered,
    setShowFavoritePopup,
    setShowOnlyFavoriteProperties,
    setTriggerDocTab,
    setTriggerNotesTab
} from "../../../store/mapTriggers";
import TypeString from "../../../domain/valueObject/TypeString";
import PartMyPropertiesSkeleton from "../../parts/PartMyPropertiesSkeleton";
import FavoritePropertyService from "../../../domain/favoriteProperty/FavoritePropertyService";
import { Tooltip } from "antd";
import PropertySearchQuery from "../../../domain/property/queries/PropertySearchQuery";
import { useSearchParams } from "react-router-dom";
import PartFilterFavorites from "../../parts/PartFilterFavorites";
import favoritePropertySliceState, {
    setFavoriteCities,
    setFavoritePropTypes,
    setFavoriteStarReasons,
    setFavoriteStarTypes,
    setFavoriteStates,
    setFavoritesProperties,
    setFavoritePipelineCapacity
} from "../../../store/favoriteProperty";
import FavoriteFilterViewMapper from "../../../domain/favoriteFilter/FavoriteFilterViewMapper";
import FavoriteAdditionalDataMapper from "../../../domain/favoriteAdditionalData/FavoriteAdditionalDataViewMapper";

import indexSliceState, {
    setCalculatorSlideout,
    setAffilliatedPopup,
    setFavoriteTypeAffiliatedPropertyId,
    setIframe,
    setPropertyCardItem
} from "../../../store";
import PropertyIdMessage from "../../../domain/iFrameMessage/calculator/PropertyIdMessage";
import PropertyViewMapper from "../../../domain/property/PropertyViewMapper";
import PropertyAddressMessage from "../../../domain/iFrameMessage/calculator/PropertyAddressMessage";
import Collection from "../../../domain/valueObject/Collection";
import { WHERE_FROM_CTA } from "../../../utils/constants";
import { useAuth0 } from "@auth0/auth0-react";

const BlockMyProperties = () => {
    const responsiveState = useSelector(responsiveSliceState);
    const favoritePropertiesSlice = useSelector(favoritePropertySliceState);
    const sliceState = useSelector(indexSliceState);
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);

    const favoriteProperties = favoritePropertiesSlice.favoritesProperties;
    const pipelineCapacity = favoritePropertiesSlice.pipelineCapacity;
    const mapTriggersState = useSelector(mapTriggersSliceState);
    // eslint-disable-next-line no-unused-vars
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [lenderFiltersCollapsed, setLenderFiltersCollapsed] = useState(false);
    const [pipelineOverLimit, setOverlimit] = useState(false);
    // availableCount:0
    // maxCount:25
    // usedCount:104
    const indexSlice = useSelector(indexSliceState);
    const query: PropertySearchQuery = PropertyService.getSearchQuery();

    const { loginWithRedirect } = useAuth0();
    const columns = [
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            width: "150px",
            render: (value: any) => (
                <>
                    <Tooltip placement="topLeft" title={value}>
                        <span className="my-properties__address">
                            <FontAwesomeIcon icon={solid("star")} />
                            {value.length > 15
                                ? value.substring(0, 15) + "..."
                                : value}
                        </span>
                    </Tooltip>
                </>
            )
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
            width: "77px",
            sorter: (a: any, b: any) => a.city.localeCompare(b.city),
            render: (value: any) => (
                <>
                    <Tooltip placement="topLeft" title={value}>
                        <span className="my-properties__city">
                            {value.length > 12
                                ? value.substring(0, 12) + "..."
                                : value}
                        </span>
                    </Tooltip>
                </>
            )
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            width: "55px",
            sorter: (a: any, b: any) => a.state.localeCompare(b.state)
        },
        {
            title: "Property Type",
            dataIndex: "propertyType",
            key: "propertyType",
            width: "101px",
            render: (value: any) => (
                <>
                    <Tooltip placement="topLeft" title={value}>
                        <span className="my-properties__state">
                            {value.length > 12
                                ? value.substring(0, 12) + "..."
                                : value}
                        </span>
                    </Tooltip>
                </>
            )
        },
        {
            title: "Tags",
            dataIndex: "favoriteType",
            key: "favoriteType",
            width: "90px",
            render: (value: any, record: any) => (
                <span
                    className="my-properties__favorite"
                    onClick={(e) => {
                        dispatch(
                            setPropertyCardItem({
                                data: null,
                                isVisible: false
                            })
                        );
                        e.stopPropagation();
                        value === "Associated"
                            ? dispatch(setAffilliatedPopup(true))
                            : dispatch(setShowFavoritePopup(true));
                        handleOpenPropertyCard(record);
                        dispatch(setTriggerNotesTab(false));
                        dispatch(setTriggerDocTab(false));
                        dispatch(
                            setFavoriteTypeAffiliatedPropertyId(
                                record?.propertyId
                            )
                        );
                    }}
                >
                    {value === "Associated" ? "Key Player" : value}
                </span>
            ),
            sorter: (a: any, b: any) =>
                a.favoriteType.localeCompare(b.favoriteType)
        },
        {
            title: "Tools",
            dataIndex: "containNotes",
            key: "notes",
            width: "45px",
            render: (value: any, record: any) => {
                return (
                    <div className="tools-wrapper">
                        <span
                            className={`my-properties__notes ${
                                !value ? "disabled" : ""
                            }`}
                            role="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(setTriggerNotesTab(true));
                                handleOpenPropertyCard(record);
                            }}
                        >
                            <FontAwesomeIcon icon={faNote} />
                        </span>

                        <span
                            className={`my-properties__calculator ${
                                !record.hasCalculation ? "disabled" : ""
                            }`}
                            role="button"
                            onClick={async (e) => {
                                const propertyData =
                                    await PropertyService.getPropertyById(
                                        new TypeString(record?.propertyId)
                                    );
                                const propertyCardMapped =
                                    PropertyViewMapper.mapPropertyCard(
                                        propertyData.propertyDetail
                                    );
                                e.stopPropagation();
                                new PropertyIdMessage().send(
                                    sliceState.calculatorIframe,
                                    new TypeString(record?.propertyId)
                                );
                                new PropertyAddressMessage().send(
                                    sliceState.calculatorIframe,
                                    new TypeString(propertyCardMapped.address)
                                );
                                dispatch(
                                    setCalculatorSlideout({
                                        isOpen: true,
                                        id: null,
                                        overlay: false,
                                        address: propertyCardMapped.address
                                    })
                                );
                                handleOpenPropertyCard(record);
                            }}
                        >
                            <FontAwesomeIcon icon={faCalculator} />
                        </span>

                        <span
                            className={`my-properties__zip-alerts ${
                                !record.hasAlert ? "disabled" : ""
                            }`}
                            onClick={async () => {
                                hotjar.event("ZIP Code Alerts Button Clicked");
                                try {
                                    await FavoritePropertyService.toggleAlerts(
                                        new Collection({
                                            propertyID: record.propertyId,
                                            alert: !record.hasAlert
                                        })
                                    );
                                    query.apply();
                                    dispatch(
                                        setPropertyCardHasAlerts(
                                            !record.hasAlert
                                        )
                                    );
                                    dispatch(
                                        setPropertyFavoriteActionTriggered(true)
                                    );
                                } catch (error) {
                                    console.log(
                                        error,
                                        "Error while trying to toggle alerts."
                                    );
                                } finally {
                                    dispatch(
                                        setPropertyFavoriteActionTriggered(
                                            false
                                        )
                                    );
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={record.hasAlert ? faBell : faBellSlash}
                            />
                        </span>

                        <span
                            className={`my-properties__documents ${
                                !record.hasDocuments ? "disabled" : ""
                            }`}
                            role="button"
                            onClick={() => {
                                setTimeout(() => {
                                    dispatch(setTriggerDocTab(true));
                                }, 150);
                            }}
                        >
                            <FontAwesomeIcon icon={faFile} />
                        </span>
                    </div>
                );
            }
        },
        {
            key: "propertyId",
            dataIndex: "propertyId",
            hidden: true,
            render: () => null
        }
    ];
    useEffect(() => {
        if (sliceState.isLoggedIn) {
            getFavoriteProperties();
        } else {
            setIsLoading(false);
        }
    }, [sliceState.isPaidUser, sliceState.isUnpaidUser]);

    // useEffect(() => {
    //     // Open last favorite property on first load if no propertyId is in the url
    //     if (
    //         firstLoadRef.current &&
    //         myProperties.length > 0 &&
    //         searchParams.get("propertyId") === null
    //     ) {
    //         const lastFavoriteProperty =
    //             getLastFavoriteCreatedProperty(myProperties);
    //         positionMapToLastFavoriteProperty(lastFavoriteProperty);
    //         firstLoadRef.current = false;
    //     }
    // }, [favoriteProperties]);

    useEffect(() => {
        if (
            sliceState.isLoggedIn &&
            mapTriggersState.propertyFavoriteActionTriggered
        ) {
            getFavoriteProperties();
        }
    }, [mapTriggersState.propertyFavoriteActionTriggered]);

    // const positionMapToLastFavoriteProperty = (lastFavoriteProperty: any) => {
    //     query
    //         .setPropertyId(new TypeString(lastFavoriteProperty.propertyId))
    //         .apply();
    // };

    const getFavoriteProperties = async () => {
        setIsLoading(true);
        try {
            const favoriteProperties =
                await FavoritePropertyService.getFavoriteProperties();
            dispatch(
                setFavoritesProperties(
                    FavoritePropertyViewMapper.map(favoriteProperties.items)
                )
            );
            dispatch(
                setFavoriteCities(
                    FavoriteFilterViewMapper.map(favoriteProperties.filters)
                        .cities
                )
            );
            dispatch(
                setFavoritePropTypes(
                    FavoriteFilterViewMapper.map(favoriteProperties.filters)
                        .propertyTypes
                )
            );
            dispatch(
                setFavoriteStates(
                    FavoriteFilterViewMapper.map(favoriteProperties.filters)
                        .states
                )
            );
            dispatch(
                setFavoriteStarReasons(
                    FavoriteFilterViewMapper.map(favoriteProperties.filters)
                        .starReasons
                )
            );
            dispatch(
                setFavoriteStarTypes(
                    FavoriteFilterViewMapper.map(favoriteProperties.filters)
                        .starTypes
                )
            );
            dispatch(
                setFavoritePipelineCapacity(
                    FavoriteAdditionalDataMapper.map(
                        favoriteProperties.additionalData
                    ).pipelineCapacity
                )
            );
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenPropertyCard = (record: any) => {
        if (record.propertyId) {
            query.setPropertyId(new TypeString(record.propertyId)).apply();
        }
    };

    const customNoDataMessage = () => {
        return (
            <div className="my-properties__no-data-wrapper">
                <h1 className="my-properties__no-data-title">
                    Looks like you don’t have any properties in your Pipeline
                    yet.
                </h1>
                <p className="my-properties__no-data-subtitle">
                    Search for a property in the search bar or zoom in on the
                    map and click on any property card to add it to `My
                    Pipeline`.
                </p>
                <p className="my-properties__no-data-text">
                    Properties you are associated with will automatically be
                    added to your Pipeline. To associate yourself with a
                    property, click the link under ‘Service Providers’ on the
                    ‘Contacts’ tab from any property card.
                </p>
            </div>
        );
    };

    const customNotLoggedInMessage = () => {
        return (
            <div className="my-properties__no-data-wrapper">
                <h1 className="my-properties__no-data-title">
                    Free Signup or Login
                </h1>
                <span className="my-properties__no-data-subtitle">
                    <button
                        className="my-properties__results-info-text-btn"
                        onClick={() => {
                            loginWithRedirect({
                                redirectUri: window.location.href
                            });
                        }}
                    >
                        Sign Up
                    </button>{" "}
                    or{" "}
                    <button
                        className="my-properties__results-info-text-btn"
                        onClick={() => {
                            loginWithRedirect({
                                redirectUri: window.location.href
                            });
                        }}
                    >
                        Log In
                    </button>{" "}
                    to see properties in your <br /> pipeline and use
                    marketplace tools.
                </span>
            </div>
        );
    };

    const locale = {
        emptyText: customNoDataMessage()
    };

    const locale2 = {
        emptyText: customNotLoggedInMessage()
    };

    const updateStateOnSubmit = () => {
        setLenderFiltersCollapsed(false);
    };

    useEffect(() => {
        if (pipelineCapacity.availableCount === 0) {
            setOverlimit(true);
        } else {
            setOverlimit(false);
        }
    }, [pipelineCapacity]);
    return (
        <div
            className={`my-properties ${
                responsiveState.overlays.myPropertiesOverlay
                    ? "propertiesOpen"
                    : "propertiesClosed"
            }`}
        >
            <div className="indcs__header">
                <div className="indcs__header-wrap">
                    <PartButton
                        type="secondary"
                        onClick={() => {
                            dispatch(setCurrentView("dashboard"));
                            dispatch(
                                setOverlays({
                                    ...responsiveState.overlays,
                                    myPropertiesOverlay: false
                                })
                            );
                        }}
                    >
                        <span
                            onClick={() => {
                                hotjar.event("My Properties Collapse - mobile");
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> tools
                        </span>
                    </PartButton>
                    <h3>MY PROPERTIES</h3>
                </div>
            </div>
            {indexSlice.isLoggedIn && (
                <div className="my-properties__switch">
                    <Switch
                        checked={mapTriggersState.showOnlyFavoriteProperties}
                        onChange={(checked: boolean) => {
                            dispatch(setShowOnlyFavoriteProperties(checked));
                        }}
                    />
                    <span className="my-properties__switch-label">
                        Show only my properties on the map{" "}
                        <span
                            className={`my-properties__switch-label ${
                                pipelineOverLimit ? "pipeline-over-limit" : ""
                            }`}
                        >
                            {favoriteProperties &&
                                favoriteProperties.length > 0 && (
                                    <Tooltip
                                        placement="top"
                                        title="Total number of properties in `My Pipeline`. Properties are added when a property marker on the map is clicked and property card is viewed."
                                        overlayStyle={{ maxWidth: "280px" }}
                                    >
                                        (
                                        <span
                                            className={`favorite-count ${
                                                pipelineOverLimit
                                                    ? "pipeline-over-limit"
                                                    : ""
                                            }`}
                                        >
                                            {pipelineCapacity.usedCount}
                                        </span>
                                        /
                                        <span
                                            className={`pipeline-limit ${
                                                pipelineOverLimit
                                                    ? "pipeline-over-limit"
                                                    : ""
                                            }`}
                                        >
                                            {pipelineCapacity.maxCount ===
                                            100000
                                                ? "Unlimited"
                                                : pipelineCapacity.maxCount}
                                        </span>
                                        )
                                    </Tooltip>
                                )}
                            {pipelineOverLimit && (
                                <span
                                    className="pipeline-over-limit_cta"
                                    onClick={() => {
                                        dispatch(
                                            setIframe({
                                                show: true,
                                                street: "",
                                                city: "",
                                                propId: "",
                                                mapLat: "",
                                                mapLng: "",
                                                mapZoom: "",
                                                type: "join",
                                                authUser: "true",
                                                whereFrom:
                                                    WHERE_FROM_CTA.NON_PAID_USER_NAV_BAR_MEMBERSHIP_UPGRADE
                                            })
                                        );
                                    }}
                                >
                                    You’ve reached your limit
                                </span>
                            )}
                        </span>
                    </span>

                    <span
                        className={`lender__filters-collapser ${
                            !lenderFiltersCollapsed ? "collapsed" : ""
                        }`}
                        onClick={() => {
                            setLenderFiltersCollapsed(!lenderFiltersCollapsed);
                        }}
                    >
                        Filters <FontAwesomeIcon icon={faChevronUp} />
                    </span>
                </div>
            )}
            <div className={!lenderFiltersCollapsed ? "collapsed" : ""}>
                <PartFilterFavorites
                    setIsLoading={setIsLoading}
                    updateStateOnSubmit={updateStateOnSubmit}
                />
            </div>

            {!indexSlice.isLoggedIn ? (
                <div>
                    <Table
                        locale={locale2}
                        columns={columns}
                        pagination={false}
                        style={{
                            height: window.innerWidth < 768 ? "100%" : "214px",
                            overflow: "hidden",
                            // overflowY: "auto",
                            paddingBottom: "35px"
                        }}
                    />
                </div>
            ) : isLoading ? (
                <PartMyPropertiesSkeleton />
            ) : (
                <Table
                    locale={locale}
                    dataSource={favoriteProperties}
                    columns={columns}
                    pagination={false}
                    scroll={{
                        x:
                            favoriteProperties && favoriteProperties.length > 0
                                ? "max-content"
                                : undefined,
                        y: window.innerWidth < 768 ? "50dvh" : "214px"
                    }}
                    style={{
                        height: window.innerWidth < 768 ? "100%" : "214px",
                        overflow: "hidden",
                        // overflowY: "auto",
                        paddingBottom: "35px"
                    }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                handleOpenPropertyCard(record);
                                dispatch(setTriggerNotesTab(false));
                                dispatch(setTriggerDocTab(false));
                            }
                        };
                    }}
                />
            )}
        </div>
    );
};

export default BlockMyProperties;
