import Exception from "../../../utils/exceptions/Exception";
import Collection from "../../valueObject/Collection";

export default class RenterType {
    private readonly FIRST_TIME_RENTER = "FIRST_TIME_RENTER";
    private readonly EXISTING_RENTER = "EXISTING_RENTER";

    private _name: string;

    constructor(value: string) {
        this._validateValue(value);
        this._name = this._formatToSentenceCase(value);
    }

    get name(): string {
        return this._name;
    }

    private _getAvailableTypes(): Collection {
        return new Collection([this.FIRST_TIME_RENTER, this.EXISTING_RENTER]);
    }

    private _formatToSentenceCase(value: string) {
        const formattedName = value
            .split("_")
            .map((item: string) => {
                return (
                    item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
                );
            })
            .join(" ");

        return formattedName;
    }

    private _validateValue(value: string) {
        if (!this._getAvailableTypes().contains(value)) {
            throw new Exception("INVALID_RENTER_TYPE", 500);
        }
    }
}
