import PropertyType from "../property/valueObject/PropertyType";
import TypeFloat from "../valueObject/TypeFloat";
import TypePositiveInteger from "../valueObject/TypePositiveInteger";
import TypeString from "../valueObject/TypeString";
import LeaseTermType from "./valueObject/LeaseTermType";
import MoveInTimeframeType from "./valueObject/MoveInTimeframeType";
import RenterType from "./valueObject/RenterType";
import TenantLeadStatusType from "./valueObject/TenantLeadStatusType";

export default class TenantLeadEntity {
    private _tenandLeadId: TypeString;
    private _email: TypeString;
    private _firstName: TypeString;
    private _lastName: TypeString;
    private _phoneNumber: TypeString;
    private _propertyType: PropertyType;
    private _city: TypeString;
    private _state: TypeString;
    private _companyName: TypeString;
    private _squareFootage: TypePositiveInteger;
    private _monthlyBudget: TypeFloat;
    private _renterType: RenterType;
    private _leaseTerm: LeaseTermType;
    private _notes: TypeString;
    private _moveInTimeframe: MoveInTimeframeType;
    private _status: TenantLeadStatusType;
    private _submissionDate: Date;

    get tenandLeadId(): TypeString {
        return this._tenandLeadId;
    }
    setTenandLeadId(value: TypeString) {
        this._tenandLeadId = value;
        return this;
    }
    hasTenantLeadId(): boolean {
        return this._tenandLeadId instanceof TypeString;
    }

    get email(): TypeString {
        return this._email;
    }
    setEmail(value: TypeString) {
        this._email = value;
        return this;
    }
    hasEmail(): boolean {
        return this._email instanceof TypeString;
    }

    get firstName(): TypeString {
        return this._firstName;
    }
    setFirstName(value: TypeString) {
        this._firstName = value;
        return this;
    }
    hasFirstName(): boolean {
        return this._firstName instanceof TypeString;
    }

    get lastName(): TypeString {
        return this._lastName;
    }
    setLastName(value: TypeString) {
        this._lastName = value;
        return this;
    }
    hasLastName(): boolean {
        return this._lastName instanceof TypeString;
    }

    get phoneNumber(): TypeString {
        return this._phoneNumber;
    }
    setPhoneNumber(value: TypeString) {
        this._phoneNumber = value;
        return this;
    }
    hasPhoneNumber(): boolean {
        return this._phoneNumber instanceof TypeString;
    }

    get propertyType(): PropertyType {
        return this._propertyType;
    }
    setPropertyType(value: PropertyType) {
        this._propertyType = value;
        return this;
    }
    hasPropertyType(): boolean {
        return this._propertyType instanceof PropertyType;
    }

    get city(): TypeString {
        return this._city;
    }
    setCity(value: TypeString) {
        this._city = value;
        return this;
    }
    hasCity(): boolean {
        return this._city instanceof TypeString;
    }

    get state(): TypeString {
        return this._state;
    }
    setState(value: TypeString) {
        this._state = value;
        return this;
    }
    hasState(): boolean {
        return this._state instanceof TypeString;
    }

    get squareFootage(): TypePositiveInteger {
        return this._squareFootage;
    }
    setSquareFootage(value: TypePositiveInteger) {
        this._squareFootage = value;
        return this;
    }
    hasSquareFootage(): boolean {
        return this._squareFootage instanceof TypePositiveInteger;
    }

    get monthlyBudget(): TypeFloat {
        return this._monthlyBudget;
    }
    setMonthlyBudget(value: TypeFloat) {
        this._monthlyBudget = value;
        return this;
    }
    hasMonthlyBudget(): boolean {
        return this._monthlyBudget instanceof TypeFloat;
    }

    get renterType(): RenterType {
        return this._renterType;
    }
    setRenterType(value: RenterType) {
        this._renterType = value;
        return this;
    }
    hasRenterType(): boolean {
        return this._renterType instanceof RenterType;
    }

    get notes(): TypeString {
        return this._notes;
    }
    setNotes(value: TypeString) {
        this._notes = value;
        return this;
    }
    hasNotes(): boolean {
        return this._notes instanceof TypeString;
    }

    get leaseTerm(): LeaseTermType {
        return this._leaseTerm;
    }

    setLeaseTerm(value: LeaseTermType) {
        this._leaseTerm = value;
        return this;
    }

    hasLeaseTerm(): boolean {
        return this._leaseTerm instanceof LeaseTermType;
    }

    get moveInTimeframe(): MoveInTimeframeType {
        return this._moveInTimeframe;
    }
    setMoveInTimeframe(value: MoveInTimeframeType) {
        this._moveInTimeframe = value;
        return this;
    }
    hasMoveInTimeframe(): boolean {
        return this._moveInTimeframe instanceof MoveInTimeframeType;
    }

    get status(): TenantLeadStatusType {
        return this._status;
    }
    setStatus(value: TenantLeadStatusType) {
        this._status = value;
        return this;
    }
    hasStatus(): boolean {
        return this._status instanceof TenantLeadStatusType;
    }

    get submissionDate(): Date {
        return this._submissionDate;
    }
    setSubmissionDate(value: Date) {
        this._submissionDate = value;
        return this;
    }
    hasSubmissionDate(): boolean {
        return this._submissionDate instanceof Date;
    }

    get companyName(): TypeString {
        return this._companyName;
    }
    setCompanyName(value: TypeString) {
        this._companyName = value;
        return this;
    }
    hasCompanyName(): boolean {
        return this._companyName instanceof TypeString;
    }
}
