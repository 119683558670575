export interface IFannieFreddyLenderContactInfo {
    officer: string;
    title: string;
    street_address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    email: string;
    lending_area: string;
}

export interface IFannieFreddyLender {
    id: number;
    name: string;
    url: string;
    logo: string;
    freddie_conventional_dus: boolean;
    freddie_sbl: boolean;
    fannie_conventional_dus: boolean;
    fannie_sbl: boolean;
    contact_info: Array<IFannieFreddyLenderContactInfo>;
    primary_contact_info?: Array<IFannieFreddyLenderContactInfo>;
}

const fannieFreddielendersList: Array<IFannieFreddyLender> = [
    {
        id: 1,
        name: "Arbor Commercial Funding I, LLC",
        url: "https://arbor.com/",
        logo: "arbor.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "John Caulfield",
                title: "Chief Operating Officer, Agency Lending",
                street_address: "333 Earle Ovington Blvd., Suite 900",
                city: "Uniondale",
                state: "NY",
                zip: "11553",
                phone: "(516) 506-4410",
                email: "",
                lending_area: "Nationwide"
            }
        ],
        primary_contact_info: [
            {
                officer: "Stephen York",
                title: "",
                street_address: "",
                city: "",
                state: "",
                zip: "",
                phone: "(212) 389-6550",
                email: "TeamYork@Arbor.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 2,
        name: "Basis Investment Group",
        url: "http://www.basisinvgroup.com/",
        logo: "basis.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Kunle Shoyombo",
                title: "Chief Investment Officer",
                street_address: "75 Broad St., Suite 2110",
                city: "New York",
                state: "NY",
                zip: "10004",
                phone: "(212) 842‐5713",
                email: "",
                lending_area: ""
            },
            {
                officer: "Tammy K. Jones",
                title: "Chief Executive Officer",
                street_address: "",
                city: "New York",
                state: "NY",
                zip: "10004",
                phone: "(212) 842-5714",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 3,
        name: "Bellwether Enterprise Real Estate Capital, LLC",
        url: "http://bellwetherenterprise.com/",
        logo: "bellwether.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "Jeff Patton",
                title: "Executive Vice President, National Director of Agency Relationships",
                street_address: "2204 Lakeshore Drive, Suite 304",
                city: "Birmingham",
                state: "AL",
                zip: "35209",
                phone: "(205) 588-6679",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 4,
        name: "Berkadia Commercial Mortgage, LLC",
        url: "http://www.berkadia.com/",
        logo: "berkadia.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "Dan Brendes",
                title: "Senior Vice President, Head of GSE Lending",
                street_address: "4445 Willard Avenue, Suite 1200",
                city: "Chevy Chase",
                state: "MD",
                zip: "20815",
                phone: "(301) 202-3551",
                email: "",
                lending_area: ""
            },
            {
                officer: "Josh Bodin",
                title: "Senior Vice President, Securities Trading",
                city: "Ambler",
                state: "PA",
                street_address: "",
                zip: "",
                phone: "(240) 507-0858",
                email: "Josh.Bodin@berkadia.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 5,
        name: "Capital One, National Association",
        url: "https://www.capitalone.com/commercial/multifamily/",
        logo: "capitalone.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Kate Byford",
                title: "Executive Vice President, Head of Agency Finance",
                street_address: "2 Bethesda Metro Center, 10th Floor",
                city: "Bethesda",
                state: "MD",
                zip: "20814",
                phone: "(301) 718-6780",
                email: "",
                lending_area: ""
            },
            {
                officer: "Phyllis Klein",
                title: "Senior Vice President, Head of Production",
                street_address: "",
                city: "Bethesda",
                state: "MD",
                zip: "",
                phone: "(301) 310-2412",
                email: "phyllis.klein@capitalone.com",
                lending_area: ""
            },
            {
                officer: "Michael Maidhof",
                title: "Senior Vice President of Multifamily Finance",
                street_address: "",
                city: "New York",
                state: "NY",
                zip: "",
                phone: "(646) 231-9750",
                email: "michael.maidhof@capitalone.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 6,
        name: "CBRE Multifamily Capital, Inc.",
        url: "https://www.cbre.us/",
        logo: "cbre.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "Kyle Draeger",
                title: "Senior Managing Director",
                street_address: "33 Arch Street, 29th Floor",
                city: "Boston",
                state: "MA",
                zip: "02110",
                phone: "(617) 217-6044",
                email: "",
                lending_area: ""
            },
            {
                officer: "Kelli Carhart",
                title: "Executive Managing Director",
                street_address: "",
                city: "Austin",
                state: "TX",
                zip: "",
                phone: "(512) 482-5588",
                email: "kelli.carhart@cbre.com",
                lending_area: ""
            },
            {
                officer: "Dan Winzeler",
                title: "Managing Director",
                street_address: "",
                city: "Orlando",
                state: "FL",
                zip: "",
                phone: "(407) 404-5072",
                email: "Dan.winzeler@cbre.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 7,
        name: "Citi Community Capital",
        url: "https://www.citibank.com/icg/sa/citicommunitycapital/",
        logo: "citi.png",
        freddie_conventional_dus: false,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Barry Krinsky",
                title: "National Production Manager",
                street_address: "7400 W Camino Real Suite 130-A",
                city: "Boca Raton",
                state: "FL",
                zip: "33433",
                phone: "(561) 347-3254",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 8,
        name: "Colliers Mortgage LLC",
        url: "https://www2.colliers.com/en/services/colliers-mortgage/agency-finance",
        logo: "colliers.png",
        freddie_conventional_dus: false,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Tim Larkin",
                title: "Senior Vice President, Operations | Agency Lending",
                street_address: "90 South 7th Street, Suite 4300",
                city: "Minneapolis",
                state: "MN",
                zip: "55402-4108",
                phone: "(612) 317-2161",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 9,
        name: "CPC Mortgage Company",
        url: "https://communityp.com/products-services/mortgage-lending/",
        logo: "cpc.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: false,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "Nicole Ferreira",
                title: "President",
                street_address: "220 East 42nd Street, 16th Floor",
                city: "New York",
                state: "NY",
                zip: "10017",
                phone: "(646) 873-6790",
                email: "",
                lending_area: ""
            },
            {
                officer: "Alexander Hajek",
                title: "Agency SBL Production Manager",
                street_address: "220 East 42nd Street, 16th Floor",
                city: "Albany",
                state: "NY",
                zip: "10017",
                phone: "(518) 618-4259",
                email: "ahajek@communityp.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 10,
        name: "Grandbridge Real Estate Capital, LLC",
        url: "https://www.grandbridge.com/",
        logo: "grandbridge.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Frank Guzikowski",
                title: "Executive Vice President – Director, Multifamily Lending",
                street_address: "1200 Brickell Avenue, Suite 1010",
                city: "Miami",
                state: "FL",
                zip: "33131",
                phone: "(305) 523-1603",
                email: "",
                lending_area: ""
            },
            {
                officer: "James Shiles",
                title: "National Production Manager",
                street_address: "1200 Brickell Avenue, Suite 1010",
                city: "Summit",
                state: "NJ",
                zip: "",
                phone: "(917) 533-4664",
                email: "james.shiles@grandbridge.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 11,
        name: "Greystone Servicing Company LLC",
        url: "https://www.greyco.com/",
        logo: "greystone.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "Chip Hudson and Kevin Williams",
                title: "Co-CEO’s of Agency Lending",
                street_address: "1715 Aaron Brenner Drive, Suite 500",
                city: "Memphis",
                state: "TN",
                zip: "38120",
                phone: "(901) 399-7720",
                email: "",
                lending_area: ""
            },
            {
                officer: "Richard Martinez",
                title: "Head of Production - Agency",
                street_address: "1715 Aaron Brenner Drive, Suite 500",
                city: "New York",
                state: "NY",
                zip: "",
                phone: "(917) 472-2856",
                email: "richard.martinez@greyco.com",
                lending_area: ""
            },
            {
                officer: "Rick Wolf",
                title: "Executive Vice President",
                street_address: "",
                city: "New York City",
                state: "NY",
                zip: "",
                phone: "(202) 701-6400",
                email: "rick.wolf@greyco.com",
                lending_area: ""
            }
        ],
        primary_contact_info: [
            {
                officer: "Avrom Forman",
                title: "",
                street_address: "",
                city: "",
                state: "",
                zip: "",
                phone: "(212) 896-9128",
                email: "Avrom.Forman@greyco.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 12,
        name: "HomeStreet Bank",
        url: "http://www.homestreet.com/capital/index.aspx?detect=yes",
        logo: "homestreet.png",
        freddie_conventional_dus: false,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "Bill Endresen",
                title: "Executive Vice President",
                street_address: "135 S. State College Blvd, Ste 600",
                city: "Brea",
                state: "CA",
                zip: "92821",
                phone: "(714) 482-1120",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 13,
        name: "JLL Real Estate Capital, LLC",
        url: "https://www.us.jll.com/en/industries/multifamily",
        logo: "jll.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Karen Shea",
                title: "Managing Director",
                street_address: "1850 Towers Crescent Plaza, 3rd Floor",
                city: "Vienna",
                state: "VA",
                zip: "22182",
                phone: "(703) 891-8392",
                email: "",
                lending_area: ""
            },
            {
                officer: "Steve Henderson",
                title: "Senior Managing Director",
                street_address: "1850 Towers Crescent Plaza, 3rd Floor",
                city: "Houston",
                state: "TX",
                zip: "",
                phone: "(713) 852-3580",
                email: "steve.henderson@jll.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 14,
        name: "JPMorgan Chase",
        url: "https://www.jpmorgan.com/commercial-banking/solutions/commercial-real-estate/agency-lending",
        logo: "jpmorgan.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Vince Toye",
                title: "Managing Director",
                street_address: "237 Park Avenue, Floor 6",
                city: "New York",
                state: "NY",
                zip: "10017",
                phone: "(212) 270-4046",
                email: "",
                lending_area: ""
            },
            {
                officer: "Josh Seiff",
                title: "Head of Secondary Markets",
                street_address: "237 Park Avenue, Floor 6",
                city: "Washington",
                state: "DC",
                zip: "",
                phone: "(202) 916-3164",
                email: "josh.seiff@jpmorgan.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 15,
        name: "KeyBank National Association",
        url: "https://www.key.com/",
        logo: "keybank.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Janette O’Brien",
                title: "Agency Production Leader",
                street_address: "127 Public Square, 8th Floor",
                city: "Cleveland",
                state: "OH",
                zip: "44114",
                phone: "(216) 689-4784",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 16,
        name: "Lument",
        url: "https://www.lument.com/",
        logo: "lument.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "James Flynn",
                title: "Chief Executive Officer",
                street_address: "230 Park Avenue, 20th Floor",
                city: "New York",
                state: "NY",
                zip: "10169",
                phone: "(212) 317-5700",
                email: "",
                lending_area: ""
            },
            {
                officer: "Ian Monk",
                title: "Managing Director - Deputy Chief Production Officer",
                street_address: "",
                city: "New York",
                state: "NY",
                zip: "",
                phone: "(212) 588-2156",
                email: "ian.monk@lument.com",
                lending_area: ""
            },
            {
                officer: "Rick Warren",
                title: "Senior Managing Director",
                street_address: "",
                city: "Irvine",
                state: "CA",
                zip: "",
                phone: "(949) 221-6684",
                email: "rick.warren@lument.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 17,
        name: "M&T Realty Capital Corporation",
        url: "http://www.mandtrealtycapital.com/",
        logo: "m_and_t.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Mike Edelman",
                title: "President",
                street_address: "350 Park Avenue, 5th Floor",
                city: "New York",
                state: "NY",
                zip: "10022",
                phone: "(212) 350-2632",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 18,
        name: "National Cooperative Bank*",
        url: "https://www.ncb.coop/",
        logo: "ncb.png",
        freddie_conventional_dus: false,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Munevver Yolas",
                title: "Executive Vice President",
                street_address: "2011 Crystal Drive Suite 800",
                city: "Arlington",
                state: "VA",
                zip: "22202",
                phone: "(703) 302-1919",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 19,
        name: "Newmark",
        url: "http://www.berkpoint.com/index.php",
        logo: "newmark.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Sharon Karaffa",
                title: "Vice Chairman and Co-Head of Production - Multifamily Capital Markets",
                street_address: "1420 Spring Hill Road, 6th Floor",
                city: "McLeane",
                state: "VA",
                zip: "22102",
                phone: "(800) 229-6843",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 20,
        name: "Newpoint Real Estate Capital LLC333",
        url: "https://newpoint.com/",
        logo: "newpoint.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Jeff Lee",
                title: "President",
                street_address: "5404 Wisconsin Avenue",
                city: "Chevy Chase",
                state: "MD",
                zip: "20815",
                phone: "(469) 440-5600",
                email: "",
                lending_area: ""
            },
            {
                officer: "Geraldine Borger",
                title: "Head of Production",
                street_address: "",
                city: "Chevy Chase",
                state: "MD",
                zip: "",
                phone: "(301) 346-4555",
                email: "(geraldine.borger@newpoint.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 21,
        name: "Northmarq",
        url: "http://www.northmarq.com/",
        logo: "northmarq.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Jayson F. Donaldson",
                title: "President",
                street_address: "8300 Douglas Avenue, Suite 650",
                city: "Dallas",
                state: "TX",
                zip: "75225",
                phone: "(301) 718-6678",
                email: "",
                lending_area: ""
            },
            {
                officer: "Paul Cairns",
                title: "Senior Vice President/Managing Director",
                street_address: "",
                city: "Bloomington",
                state: "MN",
                zip: "",
                phone: "(952) 356-0083",
                email: "pcairns@northmarq.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 22,
        name: "PGIM Real Estate",
        url: "http://www.prumortgagecapital.com/",
        logo: "pgim.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Stephanie Wiggins",
                title: "Head of Production, Agency Lending",
                street_address: "4350 Fairfax Dr., Ste. 700",
                city: "Arlington",
                state: "VA",
                zip: "22203",
                phone: "(571) 215-7267",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 23,
        name: "Pinnacle Financial Partners",
        url: "https://www.pnfp.com",
        logo: "pinnacle.png",
        freddie_conventional_dus: false,
        freddie_sbl: true,
        fannie_conventional_dus: false,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Jim Going",
                title: "Senior Vice President",
                street_address: "",
                city: "Dallas",
                state: "TX",
                zip: "",
                phone: "(901) 488-3533",
                email: "jim.going@pnfp.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 24,
        name: "PNC Real Estate",
        url: "https://www.pnc.com/webapp/unsec/RealEstateFinanceHP.do?siteArea=/PNC/Home/Corporate+and+Institutional/PNC+Real+Estate/Commercial+Real+Estate+Home",
        logo: "pnc.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Todd Weaver",
                title: "Executive Vice President and Head of Agency Production",
                street_address: "13355 Noel Rd., Suite 1770",
                city: "Dallas",
                state: "TX",
                zip: "75240",
                phone: "(972) 361-2130",
                email: "",
                lending_area: ""
            }
        ]
    },
    {
        id: 25,
        name: "Ready Capital",
        url: "https://readycapital.com",
        logo: "ready_capital.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "Adam Zausmer",
                title: "Chief Credit Officer",
                street_address: "",
                city: "New York",
                state: "NY",
                zip: "",
                phone: "(212) 257-4657",
                email: "adam.zausmer@readycapital.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 26,
        name: "Regions Real Estate Capital Markets",
        url: "https://www.regions.com/commercial-banking/real-estate-banking/real-estate-capital-markets",
        logo: "regions.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        contact_info: [
            {
                officer: "Troy Marek",
                title: "Managing Director, Head of CRE Permanent Lending Originations",
                street_address: "1900 Fifth Avenue North",
                city: "Birmingham",
                state: "AL",
                zip: "35203",
                phone: "(205) 264-5294",
                email: "",
                lending_area: ""
            },
            {
                officer: "Jason Scott",
                title: "Managing Director, Head of Conventional Loan Production",
                street_address: "",
                city: "Atlanta",
                state: "GA",
                zip: "",
                phone: "(404) 279-7488",
                email: "Jason.Scott@Regions.com",
                lending_area: ""
            },
            {
                officer: "Ann Atkinson",
                title: "Small Loan & Market Real Estate Production Manager",
                street_address: "",
                city: "Irvine",
                state: "CA",
                zip: "",
                phone: "(949) 381-2766",
                email: "ann.atkinson@regions.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 27,
        name: "Walker & Dunlop, LLC",
        url: "https://www.walkerdunlop.com/funding-your-vision/financing-options/fannie-mae/",
        logo: "walker_and_dunlop.png",
        freddie_conventional_dus: true,
        freddie_sbl: true,
        fannie_conventional_dus: true,
        fannie_sbl: true,
        /*

        */
        contact_info: [
            {
                officer: "Ted Patch",
                title: "Executive Vice President and Chief Production Officer, Multifamily Finance",
                street_address: "7272 Wisconsin Avenue, Suite 1300",
                city: "Bethesda",
                state: "MD",
                zip: "20814",
                phone: "(301) 215-5580",
                email: "",
                lending_area: ""
            },
            {
                officer: "Alison Williams",
                title: "Senior Vice President and Chief Production Officer, WDExpress",
                street_address: "",
                city: "Tampa",
                state: "FL",
                zip: "",
                phone: "(813) 793-6679",
                email: "awilliams@walkerdunlop.com",
                lending_area: ""
            }
        ]
    },
    {
        id: 28,
        name: "Wells Fargo Multifamily Capital",
        url: "https://www.wellsfargo.com/com/financing/real-estate/multi-family-capital",
        logo: "wells_fargo.png",
        freddie_conventional_dus: true,
        freddie_sbl: false,
        fannie_conventional_dus: true,
        fannie_sbl: false,
        contact_info: [
            {
                officer: "Mark Beisler",
                title: "Head of Production and COO",
                street_address: "1751 Pinnacle Dr, 7th Floor",
                city: "McLean",
                state: "VA",
                zip: "22102",
                phone: "(571) 341-3300",
                email: "",
                lending_area: ""
            }
        ]
    }
];

export default fannieFreddielendersList;
