import { useEffect, useState } from "react";
import {
    faArrowLeft,
    faBuildingUser,
    faStar,
    faXmarkLarge
} from "@fortawesome/pro-regular-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import responsiveSliceState, {
    setCurrentView,
    setOverlays,
    setShowMapBtn
} from "../../../store/responsive";
import PartButton from "../../parts/PartButton";
import indexSliceState, { setIframe } from "../../../store";
import { WHERE_FROM_CTA } from "../../../utils/constants";
import mapTriggersSliceState, {
    setTriggerAddressFocus,
    setTriggerTenantLeadFetch,
    setTriggerTenantLeadsAcc
} from "../../../store/mapTriggers";
import { Spin, Tooltip } from "antd";
import BlockTenantLeadsData from "./BlockTenantLeadsData";
import TenantLeadService from "../../../domain/tenantLead/TenantLeadService";
import TenantLeadViewMapper, {
    TenantLeadView
} from "../../../domain/tenantLead/TenantLeadViewMapper";

const loaderIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

interface BlockTenantLeadsProps {
    isTenantLeadExpanded: boolean;
    setIsTenantLeadExpanded: Function;
}

export default function BlockTenantLeads({
    isTenantLeadExpanded,
    setIsTenantLeadExpanded
}: BlockTenantLeadsProps) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [tenantLeads, setTenantLeads] = useState<TenantLeadView[]>([]);

    const sliceState = useSelector(indexSliceState);
    const responsiveState = useSelector(responsiveSliceState);
    const mapTriggers = useSelector(mapTriggersSliceState);

    const getTenantLeads = async () => {
        setIsLoading(true);
        try {
            const leadsResponse = await TenantLeadService.getTenantLeads({});
            setTenantLeads(TenantLeadViewMapper.map(leadsResponse));
        } catch (error) {
            console.log("Error getting tenant leads", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (sliceState.token && isTenantLeadExpanded) {
            getTenantLeads();
        }
        return () => {
            setTenantLeads([]);
            setIsTenantLeadExpanded(false);
        };
    }, [sliceState.token, isTenantLeadExpanded]);

    useEffect(() => {
        if (mapTriggers.triggerTenantLeadFetch) {
            getTenantLeads();
            dispatch(setTriggerTenantLeadFetch(false));
        }
    }, [mapTriggers.triggerTenantLeadFetch]);

    return (
        <div
            className={`tenant-leads ${
                responsiveState.overlays.tenantLeadsOverlay ? "open" : "closed"
            } ${
                !isLoading && sliceState.isPaidUser && tenantLeads.length > 0
                    ? "tenant-leads__has-cards"
                    : ""
            }`}
        >
            <div className="lender__top-wrap">
                <PartButton
                    type="secondary"
                    onClick={() => {
                        dispatch(setCurrentView("dashboard"));
                        dispatch(
                            setOverlays({
                                ...responsiveState.overlays,
                                tenantLeadsOverlay: false
                            })
                        );
                        dispatch(setShowMapBtn(true));
                    }}
                >
                    <>
                        <FontAwesomeIcon icon={faArrowLeft} /> tools
                    </>
                </PartButton>
                <h3>TENANT LEADS</h3>
            </div>
            <div className="calculator-slideout__header">
                <div className="calculator-slideout__header__title">
                    <FontAwesomeIcon icon={faBuildingUser}></FontAwesomeIcon>
                    <span className="calculator-slideout__header__title-text">
                        Tenant Leads
                    </span>
                </div>
                <div
                    onClick={() => {
                        // hotjar.event(`Underwriting Calculator - Collapse`);
                        dispatch(setTriggerTenantLeadsAcc("close"));
                        dispatch(setCurrentView("dashboard"));
                        dispatch(
                            setOverlays({
                                ...responsiveState.overlays,
                                tenantLeadsOverlay: false
                            })
                        );
                        dispatch(setShowMapBtn(true));
                        setIsTenantLeadExpanded(false);
                    }}
                >
                    <Tooltip placement="left" title="Close the Tenant Leads">
                        <FontAwesomeIcon
                            icon={faXmarkLarge}
                            style={{
                                height: "13px",
                                transform: "rotateY(180deg)",
                                cursor: "pointer"
                            }}
                        ></FontAwesomeIcon>
                    </Tooltip>
                </div>
            </div>
            <div className="tenant-leads__wrap">
                <h1>Tenant Leads</h1>
                {sliceState.isPaidUser ? (
                    isLoading ? (
                        <Spin indicator={loaderIcon} />
                    ) : tenantLeads.length > 0 ? (
                        <BlockTenantLeadsData data={tenantLeads} />
                    ) : (
                        <p className="tenant-leads__description">
                            To start receiving Tenant Leads, add your REO to MY
                            PIPELINE by Search for your REO property in the map
                            via the{" "}
                            <span
                                role="button"
                                onClick={() => {
                                    dispatch(setTriggerAddressFocus(true));

                                    const windowWidth = window.innerWidth;
                                    if (windowWidth < 768) {
                                        dispatch(
                                            setOverlays({
                                                ...responsiveState.overlays,
                                                propertySearch: true,
                                                tenantLeadsOverlay: false
                                            })
                                        );
                                        dispatch(setShowMapBtn(false));
                                    }
                                }}
                            >
                                search bar
                            </span>
                            &nbsp;or by zooming into its location, open
                            it&apos;s property card, click on the{" "}
                            <span className="with-icon">
                                Star Icon <FontAwesomeIcon icon={faStar} />
                            </span>{" "}
                            and check off “Own as GP”.
                        </p>
                    )
                ) : (
                    <p className="tenant-leads__description">
                        Access to “Tenant Leads” is a premium feature. <br />
                        <span
                            role="button"
                            onClick={() => {
                                dispatch(
                                    setIframe({
                                        show: true,
                                        street: "",
                                        city: "",
                                        propId: "",
                                        mapLat: "",
                                        mapLng: "",
                                        mapZoom: "",
                                        type: "join",
                                        authUser: "true",
                                        whereFrom:
                                            WHERE_FROM_CTA.NON_PAID_USER_PROFILE_ICON_MEMBERSHIP_UPGRADE
                                    })
                                );
                            }}
                        >
                            Become a member
                        </span>{" "}
                        to unlock this and more.
                    </p>
                )}
            </div>
        </div>
    );
}
