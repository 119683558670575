import { useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Select } from "antd";
import PartFavoritePopupSkeleton from "../parts/PartFavoritePopupSkeleton";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setFavoriteTypeAffiliatedPropertyId
} from "../../store";
import Collection from "../../domain/valueObject/Collection";
import FavoritePropertyService from "../../domain/favoriteProperty/FavoritePropertyService";
import {
    setPropertyCardMarkAsFavorite,
    setPropertyFavoriteActionTriggered,
    setTriggerPropretyServiceProviders
} from "../../store/mapTriggers";
import { hotjar } from "react-hotjar";
import TypeString from "../../domain/valueObject/TypeString";
import PropertyService from "../../domain/property/PropertyService";
import favoritePropertySliceState, {
    setVendorRoles
} from "../../store/favoriteProperty";
import VendorRoleService from "../../domain/vendorRole/VendorRoleService";
import VendorRoleViewMapper from "../../domain/vendorRole/VendorRoleViewMapper";

interface BlockServiceProvidersProps {
    vendorRolesDropdownValues?: any;
    setVendorRolesDropdownValues?: Function;
    updatePropertyCallback?: Function;
}

const BlockServiceProviders = ({
    vendorRolesDropdownValues,
    setVendorRolesDropdownValues,
    updatePropertyCallback
}: BlockServiceProvidersProps) => {
    const { Option } = Select;
    const [loading, setIsLoading] = useState(false);
    const indexSlice = useSelector(indexSliceState);
    const favoriteSlice = useSelector(favoritePropertySliceState);
    const [vendorRolesDropdownValuesLocal, setVendorRolesDropdownValuesLocal] =
        useState<any>(vendorRolesDropdownValues || []);

    const dispatch = useDispatch();

    useEffect(() => {
        if (vendorRolesDropdownValues) {
            setVendorRolesDropdownValuesLocal(vendorRolesDropdownValues);
        }
    }, [vendorRolesDropdownValues]);

    const query = PropertyService.getSearchQuery();

    const customCheckboxChecked = (item: any) => {
        return vendorRolesDropdownValuesLocal.find(
            (val: any) => val.key == item.value
        )
            ? true
            : false;
    };

    useEffect(() => {
        indexSlice.isLoggedIn &&
            VendorRoleService.getVendorRoles().then((response) => {
                dispatch(
                    setVendorRoles(VendorRoleViewMapper.map(response.items))
                );
            });
    }, [indexSlice.isLoggedIn]);

    useEffect(() => {
        if (indexSlice.favoriteTypeAffiliatedPropertyId) {
            getPropertyStar(indexSlice.favoriteTypeAffiliatedPropertyId);
        } else {
            getPropertyStar(indexSlice.propertyCardItem.data?.id.value);
        }
        return () => {
            if (setVendorRolesDropdownValues) setVendorRolesDropdownValues([]);
            else setVendorRolesDropdownValuesLocal([]);
        };
    }, [
        indexSlice.favoriteTypeAffiliatedPropertyId,
        indexSlice.propertyCardItem.data?.id.value
    ]);

    useEffect(() => {
        return () => {
            dispatch(setFavoriteTypeAffiliatedPropertyId(""));
        };
    }, []);

    const getPropertyStar = async (propertyId: string) => {
        setIsLoading(true);
        try {
            const result = await FavoritePropertyService.getPropertyStar(
                new TypeString(propertyId)
            );

            if (result && result.responseData) {
                if (
                    result.responseData.vendorRoles &&
                    result.responseData.vendorRoles.length > 0
                ) {
                    const vendorRoles = result.responseData.vendorRoles;
                    const filteredRoles = favoriteSlice.vendorRoles
                        .filter((item: any) => {
                            return vendorRoles.includes(item.value);
                        })
                        .map((item: any) => {
                            return { key: item.value, label: item.label };
                        });
                    if (setVendorRolesDropdownValues)
                        setVendorRolesDropdownValues(filteredRoles);
                    else setVendorRolesDropdownValuesLocal(filteredRoles);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const updatePropertyFavorite = async (vendorRolesValues?: any) => {
        const starReasons: any = favoriteSlice.userPropertyStar.starReasons;
        try {
            const data = new Collection({});
            data.set("propertyID", indexSlice.propertyCardItem.data.id.value);
            if (
                (vendorRolesValues && vendorRolesValues.length > 0) ||
                indexSlice.propertyCardItem.data.isFavorite.value
            ) {
                const vendorRoles = vendorRolesValues.map((item: any) => {
                    return item.key;
                });
                data.set("vendorRoles", vendorRoles);
                if (indexSlice.propertyCardItem.data.isFavorite)
                    dispatch(setPropertyCardMarkAsFavorite(true));
                if (starReasons.length > 0)
                    data.set("starReasons", starReasons);
                await FavoritePropertyService.addToFavorite(data);
                dispatch(setPropertyFavoriteActionTriggered(true));
                query.apply();
            } else {
                const propertyId = new TypeString(
                    indexSlice.propertyCardItem.data.id.value
                );
                await FavoritePropertyService.removeFromFavorite(propertyId);
                dispatch(setPropertyFavoriteActionTriggered(true));
                dispatch(setTriggerPropretyServiceProviders(false));
                query.apply();
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(setTriggerPropretyServiceProviders(false));
            dispatch(setPropertyFavoriteActionTriggered(false));
        }
    };
    return (
        <>
            <h3>Key Player, Listing Broker, or Service Provider?</h3>
            <p>
                Make your professional association with this property public for
                the world to know you are a key player in this market! Select
                your profession below and your name, contact, and profession
                will appear on this property for all users.
            </p>
            {loading ? (
                <div className="popup-favorites__dropdown-skeleton-wrap">
                    <PartFavoritePopupSkeleton length={1} />
                </div>
            ) : (
                <div className="popup-favorites__dropdown-wrap">
                    {!vendorRolesDropdownValuesLocal.length && (
                        <Checkbox
                            className="popup-favorites__placeholder-checkbox"
                            checked={false}
                        />
                    )}
                    <Select
                        mode="multiple"
                        placeholder="Select an option(s) from the list"
                        value={
                            vendorRolesDropdownValues ||
                            vendorRolesDropdownValuesLocal
                        }
                        showSearch={false}
                        disabled={loading}
                        onChange={(values, obj) => {
                            if (
                                setVendorRolesDropdownValues &&
                                updatePropertyCallback
                            ) {
                                setVendorRolesDropdownValues(obj);
                                setVendorRolesDropdownValuesLocal(obj);
                                updatePropertyCallback(obj);
                            } else {
                                setVendorRolesDropdownValuesLocal(obj);
                                dispatch(
                                    setTriggerPropretyServiceProviders(true)
                                );
                                updatePropertyFavorite(obj);
                            }
                        }}
                        onSelect={(option: any) => {
                            hotjar.event(
                                `Favorite Popup - Vendor Role: ${option} Checked - ${favoriteSlice.favoritesPopup.id}`
                            );
                        }}
                        onDeselect={(option: any) => {
                            hotjar.event(
                                `Favorite Popup - Vendor Role: ${option} Unchecked - ${favoriteSlice.favoritesPopup.id}`
                            );
                        }}
                        onDropdownVisibleChange={(open) => {
                            hotjar.event(
                                `Favorite Popup - Vendor Role Dropdown ${
                                    open ? "Open" : "Close"
                                } - ${favoriteSlice.favoritesPopup.id}`
                            );
                        }}
                        dropdownClassName="popup-favorites__dropdown"
                    >
                        {favoriteSlice.vendorRoles.map((item: any) => (
                            <Option key={item.value} value={item.value}>
                                <Checkbox
                                    id={item.value}
                                    checked={customCheckboxChecked(item)}
                                />
                                {item.label}
                            </Option>
                        ))}
                    </Select>
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
            )}
        </>
    );
};

export default BlockServiceProviders;
