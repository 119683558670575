import React from "react";
import { hotjar } from "react-hotjar";
import { useSelector, useDispatch } from "react-redux";
import LenderHelperClass from "../../domain/lender/LenderHelperClass";
import indexSliceState, {
    setFindALenderData,
    setIframe,
    setLenderFlyout,
    setFindALenderAccessedFrom,
    setCalculatorSlideout
} from "../../store";
import responsiveSliceState, {
    setCurrentView,
    setOverlays
} from "../../store/responsive";
import { WHERE_FROM_CTA } from "../../utils/constants";
import { getStateIdFromAbb } from "../../utils/mappers";
import PartButton from "./PartButton";
import SearchLendersMessage from "../../domain/iFrameMessage/findLender/SearchLendersMessage";
import Collection from "../../domain/valueObject/Collection";
import TypePositiveInteger from "../../domain/valueObject/TypePositiveInteger";
import TypeString from "../../domain/valueObject/TypeString";
import TypeBoolean from "../../domain/valueObject/TypeBoolean";
import { useAuth0 } from "@auth0/auth0-react";
import { setTriggerTenantLeadsAcc } from "../../store/mapTriggers";
interface PartPropertyCardButtonsProps {
    hasProperty: boolean;
    propertyCardData: any;
    pipelineOverLimit: any | undefined;
    isLoggedIn: any;
}
export function PartPropertyCardButtons({
    hasProperty,
    propertyCardData,
    pipelineOverLimit,
    isLoggedIn
}: PartPropertyCardButtonsProps) {
    const sliceState = useSelector(indexSliceState);
    const responsiveSlice = useSelector(responsiveSliceState);
    const dispatch = useDispatch();
    const { loginWithRedirect } = useAuth0();

    return (
        <>
            {!isLoggedIn ? (
                <div className="property-card__buttons pipeline-overlimit">
                    <PartButton
                        className="property-card__free"
                        type="primary"
                        onClick={() => {
                            loginWithRedirect({
                                redirectUri: window.location.href
                            });
                        }}
                    >
                        <span>Free Sign up / Log in</span>
                    </PartButton>
                </div>
            ) : pipelineOverLimit ? (
                <div className="property-card__buttons pipeline-overlimit">
                    <PartButton
                        className="property-card__link"
                        type="primary"
                        onClick={() => {
                            dispatch(
                                setIframe({
                                    show: true,
                                    street: "",
                                    city: "",
                                    propId: "",
                                    mapLat: "",
                                    mapLng: "",
                                    mapZoom: "",
                                    type: "join",
                                    authUser: "true",
                                    whereFrom:
                                        WHERE_FROM_CTA.NON_PAID_USER_NAV_BAR_MEMBERSHIP_UPGRADE
                                })
                            );
                        }}
                    >
                        <span>Upgrade Now</span>
                    </PartButton>
                </div>
            ) : (
                <div className="property-card__buttons two-buttons">
                    {hasProperty ? (
                        <PartButton
                            className="property-card__link"
                            type="secondary"
                            onClick={() => {
                                hotjar.event("Recommended Lenders");
                                const hjEvent: any =
                                    `Property Card Link - Recommended Lenders on ${propertyCardData.address} Click`.replaceAll(
                                        ",",
                                        " "
                                    );
                                hotjar.event(hjEvent);
                                dispatch(
                                    setLenderFlyout({
                                        isOpen: false,
                                        data: undefined,
                                        type: undefined
                                    })
                                );
                                const loanAmountPrice =
                                    LenderHelperClass.calculatePrice(
                                        propertyCardData
                                    );

                                dispatch(
                                    setFindALenderData({
                                        state: getStateIdFromAbb(
                                            sliceState.states,
                                            propertyCardData.stateAbbreviation
                                        ),
                                        propertyType:
                                            propertyCardData.propertyType,
                                        loanType: 1, // Default loan type to Permanent
                                        propertyId: propertyCardData.id,
                                        address: propertyCardData.address,
                                        calculatedLoanAmount: loanAmountPrice
                                            ? Math.round(loanAmountPrice)
                                            : undefined,
                                        lastPropertyCardClicked: {
                                            address: propertyCardData.address,
                                            propertyId: propertyCardData.id
                                        },
                                        publicLenders: false,
                                        executionTypes: []
                                    })
                                );
                                dispatch(
                                    setFindALenderAccessedFrom("propertyCard")
                                );

                                const state = getStateIdFromAbb(
                                    sliceState.states,
                                    propertyCardData.stateAbbreviation
                                );
                                const calculatedLoanAmount = loanAmountPrice
                                    ? Math.round(loanAmountPrice)
                                    : undefined;

                                new SearchLendersMessage().send(
                                    new Collection({
                                        state: new TypePositiveInteger(
                                            state || 0
                                        ),
                                        propertyType: new TypePositiveInteger(
                                            propertyCardData.propertyType
                                        ),
                                        loanType: new TypePositiveInteger(1), // Default loan type to Permanent
                                        propertyId: new TypeString(
                                            propertyCardData.id
                                        ),
                                        address: new TypeString(
                                            propertyCardData.address
                                        ),
                                        calculatedLoanAmount:
                                            new TypePositiveInteger(
                                                calculatedLoanAmount || 0
                                            ),
                                        lastPropertyCardClicked: {
                                            address: propertyCardData.address,
                                            propertyId: propertyCardData.id
                                        },
                                        publicLenders: new TypeBoolean(false),
                                        executionTypes: new Collection([])
                                    }),
                                    sliceState.lenderIframe
                                );
                                dispatch(
                                    setCalculatorSlideout({
                                        isOpen: false,
                                        id: null,
                                        address: ""
                                    })
                                );
                                dispatch(
                                    setOverlays({
                                        ...responsiveSlice.overlays,
                                        lenderDefault: true
                                    })
                                );
                            }}
                        >
                            <span>Find a Lender</span>
                        </PartButton>
                    ) : (
                        <span
                            className="property-card__listing-credits public-data"
                            role="button"
                            onClick={() => {
                                // hotjar.event(
                                //     "Icon Property Card - Credits Click"
                                // );
                                const splitAddress =
                                    sliceState.autoCompleteResult &&
                                    sliceState.autoCompleteResult?.formatted_address.split(
                                        ","
                                    );
                                const street: any = splitAddress
                                    ? splitAddress[0]
                                    : "";
                                const city: any = splitAddress
                                    ? `${splitAddress[1].trim()}, ${splitAddress[2].trim()}`
                                    : "";
                                dispatch(
                                    setIframe({
                                        show: true,
                                        street,
                                        city,
                                        // propId: "propertyCardData.id",
                                        mapLat: sliceState.propertyCardItem
                                            ?.lat,
                                        mapLng: sliceState.propertyCardItem
                                            ?.lng,
                                        mapZoom: sliceState.iframe.zoom,
                                        type: "missingProperty",
                                        authUser: "true"
                                    })
                                );
                            }}
                        >
                            Add Property to <br /> Map / My Pipeline
                            <span>
                                Report Missing Property to be added to the map.
                            </span>
                        </span>
                    )}
                    <PartButton
                        className="property-card__link"
                        type="secondary"
                        onClick={() => {
                            // hotjar.event("Get Financing");
                            // const hjEvent =
                            //     `Property Card Link - Finance Property on ${
                            //         propertyCardData.address ||
                            //         sliceState.autoCompleteResult
                            //             ?.formatted_address
                            //     } Click`.replaceAll(",", "-");
                            // hotjar.event(hjEvent);
                            dispatch(setTriggerTenantLeadsAcc("open"));
                            dispatch(setCurrentView("dashboard"));
                            dispatch(
                                setOverlays({
                                    ...responsiveSlice.overlays,
                                    indexesOverlay: false,
                                    lenderDefault: false,
                                    ratesOverlay: false,
                                    myPropertiesOverlay: false,
                                    tenantLeadsOverlay: true
                                })
                            );
                        }}
                    >
                        <span>Find a Tenant</span>
                    </PartButton>
                    <PartButton
                        className="property-card__link"
                        type="secondary"
                        onClick={() => {
                            hotjar.event("Get Financing");
                            const hjEvent =
                                `Property Card Link - Finance Property on ${
                                    propertyCardData.address ||
                                    sliceState.autoCompleteResult
                                        ?.formatted_address
                                } Click`.replaceAll(",", "-");
                            hotjar.event(hjEvent);
                            let splitAddress: any;
                            let streetLine: any;
                            let cityLine: any;
                            if (propertyCardData.id) {
                                splitAddress =
                                    propertyCardData.address.split(",");
                            } else if (
                                sliceState.autoCompleteResult?.formatted_address
                            ) {
                                splitAddress =
                                    sliceState.autoCompleteResult?.formatted_address.split(
                                        ","
                                    );
                            }
                            if (splitAddress.length === 3) {
                                streetLine = splitAddress[0];
                                cityLine = `${splitAddress[1]}, ${splitAddress[2]}`;
                            }

                            if (splitAddress.length === 4) {
                                streetLine = `${splitAddress[0]}`;
                                cityLine = `${splitAddress[1]}, ${splitAddress[2]}, ${splitAddress[3]}`;
                            } else if (splitAddress.length === 5) {
                                streetLine = `${splitAddress[0]}`;
                                cityLine = `${splitAddress[1]}, ${splitAddress[2]}, ${splitAddress[3]}, ${splitAddress[4]}`;
                            } else {
                                streetLine = splitAddress[0];
                                cityLine = `${splitAddress[1]}, ${splitAddress[2]}`;
                            }
                            dispatch(
                                setIframe({
                                    show: true,
                                    street: streetLine || "",
                                    city: cityLine || "",
                                    propId: propertyCardData.id,
                                    mapLat: sliceState.propertyCardItem?.lat,
                                    mapLng: sliceState.propertyCardItem?.lng,
                                    mapZoom: sliceState.iframe.zoom,
                                    type: "finance",
                                    authUser: "true",
                                    whereFrom:
                                        WHERE_FROM_CTA.PROPERTY_CARD_FINANCE_BUTTON
                                })
                            );
                        }}
                    >
                        <span>Free Financing Quote</span>
                    </PartButton>
                </div>
            )}
        </>
    );
}
