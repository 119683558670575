import Exception from "../../../utils/exceptions/Exception";
import Collection from "../../valueObject/Collection";

export default class LeaseTermType {
    private static readonly UP_TO_ONE_YEAR = "UP_TO_ONE_YEAR";
    private static readonly UP_TO_ONE_YEAR_NAME = "Up to 1 year";
    private static readonly TWO_YEARS = "TWO_YEARS";
    private static readonly TWO_YEARS_NAME = "2 years";
    private static readonly THREE_TO_FIVE_YEARS = "THREE_TO_FIVE_YEARS";
    private static readonly THREE_TO_FIVE_YEARS_NAME = "3-5 years";
    private static readonly FIVE_PLUS_YEARS = "FIVE_PLUS_YEARS";
    private static readonly FIVE_PLUS_YEARS_NAME = "5+ years";

    private _value: string;

    constructor(value: string) {
        this._value = value;
        this.validateValue();
    }

    get name(): string {
        return LeaseTermType.getAvailableTypeNames().get(
            LeaseTermType.getAvailableTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableTypes(): Collection {
        return new Collection([
            LeaseTermType.UP_TO_ONE_YEAR,
            LeaseTermType.TWO_YEARS,
            LeaseTermType.THREE_TO_FIVE_YEARS,
            LeaseTermType.FIVE_PLUS_YEARS
        ]);
    }

    public static getAvailableTypeNames(): Collection {
        return new Collection([
            LeaseTermType.UP_TO_ONE_YEAR_NAME,
            LeaseTermType.TWO_YEARS_NAME,
            LeaseTermType.THREE_TO_FIVE_YEARS_NAME,
            LeaseTermType.FIVE_PLUS_YEARS_NAME
        ]);
    }

    private validateValue() {
        if (!LeaseTermType.getAvailableTypes().contains(this._value)) {
            throw new Exception("INVALID_LEASE_TERM_TYPE", 500);
        }
    }
}
