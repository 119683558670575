import Exception from "../../../utils/exceptions/Exception";
import Collection from "../../valueObject/Collection";

export default class MoveInTimeframeType {
    private static readonly ASAP = "ASAP";
    private static readonly ASAP_NAME = "ASAP";
    private static readonly ONE_TO_THREE_MONTHS = "ONE_TO_THREE_MONTHS";
    private static readonly ONE_TO_THREE_MONTHS_NAME = "1-3 months";
    private static readonly THREE_TO_SIX_MONTHS = "THREE_TO_SIX_MONTHS";
    private static readonly THREE_TO_SIX_MONTHS_NAME = "3-6 months";
    private static readonly SIX_PLUS_MONTHS = "SIX_PLUS_MONTHS";
    private static readonly SIX_PLUS_MONTHS_NAME = "6+ months";

    private _value: string;

    constructor(value: string) {
        this._value = value;
        this.validateValue();
    }

    get name(): string {
        return MoveInTimeframeType.getAvailableTypeNames().get(
            MoveInTimeframeType.getAvailableTypes().findKey(
                (item: any) => item === this._value
            )
        );
    }

    public static getAvailableTypes(): Collection {
        return new Collection([
            MoveInTimeframeType.ASAP,
            MoveInTimeframeType.ONE_TO_THREE_MONTHS,
            MoveInTimeframeType.THREE_TO_SIX_MONTHS,
            MoveInTimeframeType.SIX_PLUS_MONTHS
        ]);
    }

    public static getAvailableTypeNames(): Collection {
        return new Collection([
            MoveInTimeframeType.ASAP_NAME,
            MoveInTimeframeType.ONE_TO_THREE_MONTHS_NAME,
            MoveInTimeframeType.THREE_TO_SIX_MONTHS_NAME,
            MoveInTimeframeType.SIX_PLUS_MONTHS_NAME
        ]);
    }

    private validateValue() {
        if (!MoveInTimeframeType.getAvailableTypes().contains(this._value)) {
            throw new Exception("INVALID_LEASE_TERM_TYPE", 500);
        }
    }
}
