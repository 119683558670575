import { useEffect, useState, useRef } from "react";
import { Collapse } from "antd";
import BlockIndexes from "./BlockIndexes";
import BlockInterest from "./BlockInterest";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faMinus,
    faPercent,
    faInfoCircle,
    faChartLine,
    faBuildingColumns,
    faLayerGroup,
    faCalculator,
    faNewspaper,
    faTreeCity,
    faUpRightFromSquare,
    faArrowRightToBracket,
    faHome,
    faBuildingUser
} from "@fortawesome/pro-regular-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import BlockFindALender from "./findLender/BlockFindALender";
import { useSearchParams } from "react-router-dom";
import indexSliceState, {
    setCalculatorSlideout,
    setFindALenderAccessedFrom,
    setLeasingAdminSlideout,
    setLenderFlyout,
    setShowLpInvestorPopup
} from "../../../store";
import responsiveSliceState from "../../../store/responsive";
import BlockMyProperties from "./BlockMyProperties";
import BlockLenderPortfolios from "./BlockLenderPortfolios";
import BlockVideoWalkthrough from "./BlockVideoWalkthrough";
import BlockFannieFreddieLenders from "./BlockFannieFreddieLenders";
import BlockTenantLeads from "./BlockTenantLeads";
import mapTriggersSliceState, {
    setTriggerTenantLeadsAcc
} from "../../../store/mapTriggers";
import IconLeasingAdmin from "../../icons/IconLeasingAdmin";

const { Panel } = Collapse;

interface BlockSidebarProps {
    isMenuCollapsed: boolean;
    setIsMenuCollapsed: Function;
}

const BlockSidebar = ({
    isMenuCollapsed,
    setIsMenuCollapsed
}: BlockSidebarProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const sliceState = useSelector(indexSliceState);
    const responsiveState = useSelector(responsiveSliceState);
    const mapTriggersSlice = useSelector(mapTriggersSliceState);

    const [activeKey, setActiveKey] = useState<string | undefined>();
    const [isTenantLeadsExpanded, setIsTenantLeadsExpanded] = useState(false);

    const dispatch = useDispatch();

    const ACORDION_ITEMS = {
        PIPELINE: "1",
        FIND_A_LENDER: "2",
        INTEREST_RATES: "3",
        INDEXES: "4",
        VIEW_LENDER_PORTFOLIO: "5",
        ACQUISITION_CALCULATOR: "6",
        FANNIE_FREDDIE_LENDERS: "7",
        NEWS: "8",
        TENANT_LEADS: "9",
        VIDE0_WALKTHROUGH: "10",
        LEASING_ADMIN: "11"
    };

    const TAB_PARAMS = {
        PIPELINE: "pipeline",
        FIND_A_LENDER: "fl",
        INTEREST_RATES: "rate",
        INDEXES: "indexes",
        VIEW_LENDER_PORTFOLIO: "portfolio",
        ACQUISITION_CALCULATOR: "acalc",
        FANNIE_FREDDIE_LENDERS: "ffl",
        TENANT_LEADS: "leads",
        LEASING_ADMIN: "leasingAdmin"
    };

    // When Find a Lender button is clicked on the property card, expand the sidebar menu
    useEffect(() => {
        if (
            sliceState.findALenderData.state &&
            sliceState.findALenderData.propertyType
        ) {
            setIsMenuCollapsed(false);
        }
    }, [sliceState.findALenderData.propertyId]);

    useEffect(() => {
        if (responsiveState.overlays.lenderDefault) {
            setActiveKey("2");
        }
    }, [responsiveState.overlays]);

    // This opens the sidebar menu when the user clicks on the white space around the title text
    useEffect(() => {
        if (activeKey?.length) {
            setIsMenuCollapsed(false);
        }
    }, [activeKey]);

    useEffect(() => {
        if (mapTriggersSlice.triggerTenantLeadsAcc === "open") {
            setIsMenuCollapsed(false);
            setActiveKey(ACORDION_ITEMS.TENANT_LEADS);
            dispatch(setTriggerTenantLeadsAcc(null));
        } else if (mapTriggersSlice.triggerTenantLeadsAcc === "close") {
            setActiveKey(undefined);
            dispatch(setTriggerTenantLeadsAcc(null));
        }
    }, [mapTriggersSlice.triggerTenantLeadsAcc]);

    const lenderCollapseHTML = () => (
        <div
            className={`filters__dropdowns-wrap ${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            onClick={() => {
                if (isMenuCollapsed) {
                    setIsMenuCollapsed(false);
                    setActiveKey(ACORDION_ITEMS.FIND_A_LENDER);
                } else {
                    dispatch(
                        setLenderFlyout({
                            isOpen: false,
                            data: undefined,
                            type: undefined
                        })
                    );
                }
            }}
        >
            <span className="filters__dropdowns-icon">
                <FontAwesomeIcon icon={faBuildingColumns} />
            </span>
            <span className="filters__dropdowns-text">FIND A LENDER </span>
        </div>
    );

    const tenantsCollapseHTML = () => (
        <div
            className={`filters__dropdowns-wrap ${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            onClick={() => {
                if (isMenuCollapsed) {
                    setIsMenuCollapsed(false);
                    setActiveKey(ACORDION_ITEMS.TENANT_LEADS);
                } else {
                    dispatch(
                        setLenderFlyout({
                            isOpen: false,
                            data: undefined,
                            type: undefined
                        })
                    );
                }
            }}
        >
            <span className="filters__dropdowns-icon">
                <FontAwesomeIcon icon={faBuildingUser} />
            </span>
            <span className="filters__dropdowns-text">TENANT LEADS </span>
        </div>
    );

    // const comingSoonHTML = (label: string, icon: any) => (
    //     <div
    //         className={`filters__dropdowns-wrap ${
    //             isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
    //         }`}
    //     >
    //         <span className="filters__dropdowns-icon">
    //             <FontAwesomeIcon icon={icon} />
    //         </span>
    //         <span className="filters__dropdowns-text">
    //             {label}{" "}
    //             <span className="filters__new-feature filters__new-feature--cooming-soon">
    //                 Coming Soon!
    //             </span>
    //         </span>
    //     </div>
    // );
    const collapseHeaderHTML = (
        activeKey: string | null,
        label: string,
        icon: any,
        commingSoon?: boolean,
        newFeature?: boolean,
        beta?: boolean
    ) => (
        <div
            className={`filters__dropdowns-wrap ${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            onClick={() => {
                if (isMenuCollapsed && activeKey) {
                    setIsMenuCollapsed(false);
                    setActiveKey(activeKey);
                }
                dispatch(
                    setLenderFlyout({
                        isOpen: false,
                        data: undefined,
                        type: undefined
                    })
                );
            }}
        >
            <span className="filters__dropdowns-icon">
                <FontAwesomeIcon icon={icon} />
            </span>
            <span
                className={`filters__dropdowns-text ${
                    newFeature ? "new-feature" : ""
                }`}
            >
                <span>{label}</span>{" "}
                {commingSoon && (
                    <span className="filters__new-feature filters__new-feature--cooming-soon">
                        Coming Soon!
                    </span>
                )}
                {newFeature && (
                    <span className="filters__new-feature-news">
                        New Feature
                    </span>
                )}
                {beta && <span className="filters__acq-beta">Beta</span>}
            </span>
        </div>
    );

    const aquisitionCalculatorHTML = (label: string, icon: any) => (
        <div
            className={`filters__dropdowns-wrap ${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            onClick={() => {
                hotjar.event(`Underwriting Calculator - Expand`);
                dispatch(
                    setCalculatorSlideout({
                        isOpen: true,
                        id: null,
                        address: ""
                    })
                );
                dispatch(
                    setLenderFlyout({
                        isOpen: false,
                        data: undefined,
                        type: undefined
                    })
                );
            }}
        >
            <span className="filters__dropdowns-icon">
                <FontAwesomeIcon icon={icon} />
            </span>

            <span className="filters__dropdowns-text">
                <span>
                    {label}
                    {/* <span className="filters__new-feature-calc">Beta</span> */}
                </span>
            </span>
            {/* <span className="filters__acq-beta">Beta</span> */}
            <span className="filters__dropdown-end">
                <FontAwesomeIcon
                    icon={faArrowRightToBracket}
                    style={{
                        height: "13px"
                    }}
                ></FontAwesomeIcon>
            </span>
        </div>
    );

    const leasingAdminHTML = (label: string) => (
        <div
            className={`filters__dropdowns-wrap ${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            onClick={() => {
                hotjar.event(`Leasing Admin Portal - Expand`);
                dispatch(
                    setLeasingAdminSlideout({
                        isOpen: true,
                        id: null
                    })
                );
                dispatch(
                    setLenderFlyout({
                        isOpen: false,
                        data: undefined,
                        type: undefined
                    })
                );
            }}
        >
            <span className="filters__dropdowns-icon filters__dropdowns-icon--leasing-admin">
                <IconLeasingAdmin />
            </span>

            <span className="filters__dropdowns-text">
                <span>{label}</span>
            </span>
            <span className="filters__dropdown-end">
                <FontAwesomeIcon
                    icon={faArrowRightToBracket}
                    style={{
                        height: "13px"
                    }}
                ></FontAwesomeIcon>
            </span>
        </div>
    );

    const newsHTML = (label: string, icon: any) => (
        <a
            className={`${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            href="https://news.gparency.com/"
            target="_blank"
            rel="noreferrer"
        >
            <div className="filters__dropdowns-wrap">
                <span className="filters__dropdowns-icon">
                    <FontAwesomeIcon icon={icon} />
                </span>

                <span className="filters__dropdowns-text">
                    <span className="filters__news-flex">
                        <span>{label} </span>
                        <span className="filters__dropdown-end">
                            <FontAwesomeIcon
                                icon={faUpRightFromSquare}
                                style={{
                                    height: "13px"
                                }}
                            ></FontAwesomeIcon>
                        </span>
                    </span>
                </span>
            </div>
        </a>
    );

    const indexCollapseHTML = () => (
        <div
            className={`filters__dropdowns-wrap ${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            onClick={() => {
                if (isMenuCollapsed) {
                    setIsMenuCollapsed(false);
                    setActiveKey(ACORDION_ITEMS.INDEXES);
                }
                dispatch(
                    setLenderFlyout({
                        isOpen: false,
                        data: undefined,
                        type: undefined
                    })
                );
            }}
        >
            <span className="filters__dropdowns-icon">
                <FontAwesomeIcon icon={faChartLine} />
            </span>
            <span className="filters__dropdowns-text">INDEXES </span>
        </div>
    );

    const propertiesCollapseHTML = () => (
        <div
            className={`filters__dropdowns-wrap ${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            onClick={() => {
                if (isMenuCollapsed) {
                    setIsMenuCollapsed(false);
                    setActiveKey(ACORDION_ITEMS.PIPELINE);
                }
                dispatch(
                    setLenderFlyout({
                        isOpen: false,
                        data: undefined,
                        type: undefined
                    })
                );
            }}
        >
            <span className="filters__dropdowns-icon">
                <FontAwesomeIcon icon={faTreeCity} />
            </span>
            <span className="filters__dropdowns-text">MY PIPELINE </span>
        </div>
    );

    const divRef = useRef<any>(null);
    const [isCloseToBottom, setIsCloseToBottom] = useState(false);
    const threshold = 100; // Adjust this value as needed, e.g., 50 pixels

    const checkDivPosition = () => {
        const rect = divRef.current.getBoundingClientRect();
        const viewportHeight =
            window.innerHeight || document.documentElement.clientHeight;
        const distanceToBottom = viewportHeight - rect.bottom;

        setIsCloseToBottom(distanceToBottom <= threshold);
    };

    const interestRatesCollapseHTML = () => (
        <div
            className={`filters__dropdowns-wrap ${
                isMenuCollapsed ? "filters__dropdowns-isMenuCollapsed" : ""
            }`}
            onClick={() => {
                if (isMenuCollapsed) {
                    setIsMenuCollapsed(false);
                    setActiveKey(ACORDION_ITEMS.INTEREST_RATES);
                }
                dispatch(
                    setLenderFlyout({
                        isOpen: false,
                        data: undefined,
                        type: undefined
                    })
                );
            }}
        >
            <span className="filters__dropdowns-icon">
                <FontAwesomeIcon icon={faPercent} />
            </span>
            <span className="filters__dropdowns-text">
                INTEREST RATES
                <span
                    className="filters__dropdowns-info"
                    onMouseEnter={() => {
                        checkDivPosition();
                    }}
                    onMouseLeave={() => {
                        checkDivPosition();
                    }}
                >
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div
                        ref={divRef}
                        style={{
                            top: isCloseToBottom ? " -111px" : "100%"
                        }}
                    >
                        <h5>
                            <span>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </span>
                            Interest rate ranges displayed are general
                            indicators of market conditions.
                        </h5>
                        <p>
                            These are based on various factors, including{" "}
                            <span style={{ fontWeight: "bold" }}>
                                real-time quotes from our marketplace
                            </span>{" "}
                            and{" "}
                            <span style={{ fontWeight: "bold" }}>
                                insights from our brokers.
                            </span>
                        </p>
                    </div>
                </span>
            </span>
        </div>
    );

    useEffect(() => {
        setActiveKey(
            isMenuCollapsed
                ? undefined
                : sliceState.findALenderData.state
                ? ACORDION_ITEMS.FIND_A_LENDER
                : activeKey
        );
    }, [isMenuCollapsed]);

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        if (
            currentParams?.calculatorShareId === undefined &&
            currentParams?.tab === undefined &&
            sliceState.isLoggedIn
        ) {
            setActiveKey("1");
        } else if (!sliceState.isLoggedIn) {
            setActiveKey("10");
        }
        if (currentParams !== undefined && currentParams.tab) {
            switch (currentParams.tab) {
                case TAB_PARAMS.PIPELINE:
                    setActiveKey(ACORDION_ITEMS.PIPELINE);
                    break;
                case TAB_PARAMS.FIND_A_LENDER:
                    setActiveKey(ACORDION_ITEMS.FIND_A_LENDER);
                    break;
                case TAB_PARAMS.INTEREST_RATES:
                    setActiveKey(ACORDION_ITEMS.INTEREST_RATES);
                    break;
                case TAB_PARAMS.INDEXES:
                    setActiveKey(ACORDION_ITEMS.INDEXES);
                    break;
                case TAB_PARAMS.VIEW_LENDER_PORTFOLIO:
                    setActiveKey(ACORDION_ITEMS.VIEW_LENDER_PORTFOLIO);
                    break;
                case TAB_PARAMS.ACQUISITION_CALCULATOR:
                    // Opens a slideout
                    dispatch(
                        setCalculatorSlideout({
                            isOpen: true,
                            id: null,
                            overlay: false,
                            address: ""
                        })
                    );
                    break;
                case TAB_PARAMS.FANNIE_FREDDIE_LENDERS:
                    setActiveKey(ACORDION_ITEMS.FANNIE_FREDDIE_LENDERS);
                    break;
                case TAB_PARAMS.TENANT_LEADS:
                    setActiveKey(ACORDION_ITEMS.TENANT_LEADS);
                    break;
                case TAB_PARAMS.LEASING_ADMIN:
                    setActiveKey(ACORDION_ITEMS.LEASING_ADMIN);
                    break;
                default:
                    if (sliceState.isLoggedIn)
                        setActiveKey(ACORDION_ITEMS.PIPELINE);
                    break;
            }
        }
        if (
            currentParams?.lpShow === "true" &&
            currentParams?.propertyId !== undefined
        ) {
            dispatch(
                setShowLpInvestorPopup({
                    isOpen: true,
                    propertyId: currentParams?.propertyId,
                    propertyAddress: ""
                })
            );
        }
    }, [sliceState.isLoggedIn]);

    useEffect(() => {
        if (activeKey) {
            let tabName = "";
            switch (activeKey) {
                case ACORDION_ITEMS.PIPELINE:
                    tabName = TAB_PARAMS.PIPELINE;
                    break;
                case ACORDION_ITEMS.FIND_A_LENDER:
                    tabName = TAB_PARAMS.FIND_A_LENDER;
                    break;
                case ACORDION_ITEMS.INTEREST_RATES:
                    tabName = TAB_PARAMS.INTEREST_RATES;
                    break;
                case ACORDION_ITEMS.INDEXES:
                    tabName = TAB_PARAMS.INDEXES;
                    break;
                case ACORDION_ITEMS.VIEW_LENDER_PORTFOLIO:
                    tabName = TAB_PARAMS.VIEW_LENDER_PORTFOLIO;
                    break;
                case ACORDION_ITEMS.FANNIE_FREDDIE_LENDERS:
                    tabName = TAB_PARAMS.FANNIE_FREDDIE_LENDERS;
                    break;
                case ACORDION_ITEMS.TENANT_LEADS:
                    tabName = TAB_PARAMS.TENANT_LEADS;
                    setIsTenantLeadsExpanded(true);
                    break;
                default:
                    break;
            }
            searchParams.set("tab", tabName);
            setSearchParams(searchParams);
        }
    }, [activeKey]);

    return responsiveState.isMobile ? (
        <>
            <BlockFindALender />
            <BlockTenantLeads
                isTenantLeadExpanded={isTenantLeadsExpanded}
                setIsTenantLeadExpanded={setIsTenantLeadsExpanded}
            />
            <BlockIndexes />
            <BlockInterest />
            <BlockMyProperties />
            <BlockLenderPortfolios />
            <BlockFannieFreddieLenders />
            <BlockVideoWalkthrough />
        </>
    ) : (
        <div className={`site-sidebar ${isMenuCollapsed ? "collapsed" : ""}`}>
            <div className="site-sidebar__collapse">
                <Collapse
                    className="filters__dropdown-wrap"
                    accordion
                    defaultActiveKey={ACORDION_ITEMS.VIDE0_WALKTHROUGH}
                    activeKey={activeKey}
                    bordered={false}
                    expandIcon={({ isActive }) => (
                        <div className="filters__dropdown-icon">
                            {isActive ? (
                                <FontAwesomeIcon icon={faMinus} />
                            ) : (
                                <FontAwesomeIcon icon={faPlus} />
                            )}
                        </div>
                    )}
                    expandIconPosition="end"
                    onChange={(e: any) => {
                        if (activeKey === ACORDION_ITEMS.FIND_A_LENDER) {
                            hotjar.event("Find a Lender Collapse");
                        } else if (
                            activeKey === ACORDION_ITEMS.INTEREST_RATES
                        ) {
                            hotjar.event("Interest Rates Collapse");
                        } else if (activeKey === ACORDION_ITEMS.INDEXES) {
                            hotjar.event("Indexes Collapse");
                        } else if (
                            activeKey === ACORDION_ITEMS.VIEW_LENDER_PORTFOLIO
                        ) {
                            hotjar.event(`View Lender Portfolios - Collapse`);
                        } else if (
                            activeKey === ACORDION_ITEMS.FANNIE_FREDDIE_LENDERS
                        ) {
                            hotjar.event(`Agency List - Collapse Feature`);
                        }
                        if (e) {
                            if (e === ACORDION_ITEMS.FIND_A_LENDER) {
                                hotjar.event("Find a Lender Expand");
                            } else if (e === ACORDION_ITEMS.INTEREST_RATES) {
                                hotjar.event("Interest Rates Expand");
                            } else if (e === ACORDION_ITEMS.INDEXES) {
                                hotjar.event("Indexes Expand");
                            } else if (
                                e === ACORDION_ITEMS.VIEW_LENDER_PORTFOLIO
                            ) {
                                hotjar.event(`View Lender Portfolios - Expand`);
                            } else if (
                                e === ACORDION_ITEMS.FANNIE_FREDDIE_LENDERS
                            ) {
                                hotjar.event(`Agency List - Expand Feature`);
                            } else if (e === ACORDION_ITEMS.TENANT_LEADS) {
                                setIsTenantLeadsExpanded(true);
                            }

                            let event;
                            switch (e) {
                                case ACORDION_ITEMS.PIPELINE:
                                    event = "My Properties";
                                    break;
                                case ACORDION_ITEMS.FIND_A_LENDER:
                                    event = "Find a Lender";
                                    dispatch(
                                        setFindALenderAccessedFrom("sidebar")
                                    );
                                    break;
                                case ACORDION_ITEMS.INTEREST_RATES:
                                    event = "Interest Rate Indexes";
                                    break;
                                case ACORDION_ITEMS.INDEXES:
                                    event = "Property Indexes";
                                    break;
                                case ACORDION_ITEMS.NEWS:
                                    event = "News";
                                    break;
                                case ACORDION_ITEMS.FANNIE_FREDDIE_LENDERS:
                                    event = "Agency List";
                                    break;
                            }

                            hotjar.event(`Left Nav ${event} Expand`);
                        } else {
                            hotjar.event(`Left Nav Accordion Closed`);
                        }
                        setActiveKey(e);
                    }}
                >
                    <Panel
                        header={propertiesCollapseHTML()}
                        key={ACORDION_ITEMS.PIPELINE}
                        className="lender-collapse lender-collapse--my-properties"
                        forceRender
                    >
                        <BlockMyProperties />
                    </Panel>

                    <Panel
                        header={aquisitionCalculatorHTML(
                            "UNDERWRITING CALCULATOR",
                            faCalculator
                        )}
                        key={ACORDION_ITEMS.ACQUISITION_CALCULATOR}
                        className="lender-collapse lender-collapse--calculator"
                        forceRender
                        // collapsible="disabled"
                    ></Panel>
                    <Panel
                        header={lenderCollapseHTML()}
                        key={ACORDION_ITEMS.FIND_A_LENDER}
                        className="lender-collapse"
                        forceRender
                    >
                        <BlockFindALender />
                    </Panel>
                    <Panel
                        header={tenantsCollapseHTML()}
                        key={ACORDION_ITEMS.TENANT_LEADS}
                        className="lender-collapse"
                    >
                        <BlockTenantLeads
                            isTenantLeadExpanded={isTenantLeadsExpanded}
                            setIsTenantLeadExpanded={setIsTenantLeadsExpanded}
                        />
                    </Panel>
                    <Panel
                        header={interestRatesCollapseHTML()}
                        key={ACORDION_ITEMS.INTEREST_RATES}
                        className="lender-collapse"
                        forceRender
                    >
                        <BlockInterest />
                    </Panel>

                    <Panel
                        header={indexCollapseHTML()}
                        key={ACORDION_ITEMS.INDEXES}
                        className="lender-collapse"
                        forceRender
                    >
                        <BlockIndexes />
                    </Panel>

                    <Panel
                        header={collapseHeaderHTML(
                            ACORDION_ITEMS.VIEW_LENDER_PORTFOLIO,
                            "LENDER OVERLAY",
                            faLayerGroup,
                            false,
                            false,
                            false
                        )}
                        key={ACORDION_ITEMS.VIEW_LENDER_PORTFOLIO}
                        className="lender-collapse lender-collapse--portfolio"
                        forceRender
                    >
                        <BlockLenderPortfolios />
                    </Panel>

                    <Panel
                        header={collapseHeaderHTML(
                            ACORDION_ITEMS.FANNIE_FREDDIE_LENDERS,
                            "FREDDIE MAC & FANNIE MAE MULTIFAMILY LENDERS",
                            faHome,
                            false,
                            false,
                            false
                        )}
                        key={ACORDION_ITEMS.FANNIE_FREDDIE_LENDERS}
                        className="lender-collapse lender-collapse--portfolio"
                        forceRender
                    >
                        <BlockFannieFreddieLenders />
                    </Panel>

                    <Panel
                        header={newsHTML("CRE NEWS", faNewspaper)}
                        key={ACORDION_ITEMS.NEWS}
                        className="lender-collapse lender-collapse--news"
                        forceRender
                    ></Panel>
                    <Panel
                        header={collapseHeaderHTML(
                            "10",
                            "VIDEO WALKTHROUGH",
                            faYoutube,
                            false,
                            false,
                            false
                        )}
                        key={ACORDION_ITEMS.VIDE0_WALKTHROUGH}
                        className="lender-collapse lender-collapse--video"
                        forceRender
                    >
                        <BlockVideoWalkthrough />
                    </Panel>
                    {sliceState.isLeasingAdmin && (
                        <Panel
                            header={leasingAdminHTML("LEASING ADMIN PORTAL")}
                            key={ACORDION_ITEMS.LEASING_ADMIN}
                            className="lender-collapse lender-collapse--calculator lender-collapse--leasing-admin"
                            forceRender
                        ></Panel>
                    )}
                </Collapse>
            </div>
        </div>
    );
};

export default BlockSidebar;
