import Collection from "../valueObject/Collection";

export default class TenantLeadRepository {
    _gateway: any;
    _factory: any;

    constructor(gateway: any, factory: any) {
        this._gateway = gateway;
        this._factory = factory;
    }

    async getTenantLeads(queryParams: Collection) {
        const data = new Collection(
            await this._gateway.getTenantLeads(queryParams)
        );

        let items = new Collection([]);

        items = data.map((itemData: any) => {
            return this._factory.reconstitute(new Collection(itemData));
        });

        return items;
    }
}
